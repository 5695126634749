import { useEffect, useState } from "react";
import clsx from "clsx";
import { Link as RouterLink, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { useTheme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import { Box, Divider, Drawer, Tooltip } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useAuth from "../../hooks/useAuth";
import NavSection from "../NavSection";
import CommentIcon from "../icons/CommentIcon";
import {
  HomeOutlined,
  ExperimentOutlined,
  DashboardOutlined,
  DoubleLeftOutlined,
  AppstoreOutlined,
  FastForwardOutlined,
  SyncOutlined,
  RocketOutlined,
  BulbOutlined,
  BranchesOutlined,
  PoweroffOutlined,
  CloudUploadOutlined,
  ApiOutlined,
  FileAddOutlined,
  CloudDownloadOutlined,
  DeploymentUnitOutlined,
  ExceptionOutlined,
  ProfileOutlined,
  ReadOutlined,
  SettingOutlined,
  InfoCircleOutlined,
  DoubleRightOutlined,
} from "@ant-design/icons";
import AccountPopover from "./AccountPopover";
import NotificationsPopover from "./NotificationsPopover";
import VizDevelopmentIcon from "../icons/VizDevelopment";
import Scrollbar from "../Scrollbar";
import { matchPath } from "react-router-dom";
import NavItem from "../NavItem";
import _ from "lodash";
import { getOrgData } from "../../utils/helpers";

const sectionIconStyle = {
  fontSize: "20px",
  marginRight: "12px",
};

// This code checks if the v2 links are available for the org and returns it.
const org = window.localStorage.getItem("org");
var home_v2_link = "/app/";
var report_v2_link = "/app/reports";

const demoSections = [
  {
    items: [
      {
        title: "Home",
        path: home_v2_link,
        topLevelItem: true,
        icon: <HomeOutlined style={sectionIconStyle} />,
      },
      {
        isDivider: true,
      },
      {
        title: "Modules",
        path: null,
        icon: <AppstoreOutlined style={sectionIconStyle} />,
        children: [
          {
            title: "Monitor",
            path: "/app/monitoring",
            icon: <DashboardOutlined style={sectionIconStyle} />,
          },
          {
            title: "Discover",
            path: "/app/discovery",
            icon: <BulbOutlined style={sectionIconStyle} />,
          },
          {
            title: "Experiment",
            path: null,
            icon: <ExperimentOutlined style={sectionIconStyle} />,
          },
          {
            title: "Allocate",
            path: "/app/allocate",
            icon: (
              <BranchesOutlined
                style={{ ...sectionIconStyle, transform: "rotate(90deg)" }}
              />
            ),
          },
        ],
      },
      {
        isDivider: true,
      },
      {
        title: "Automation",
        path: null,
        icon: <FastForwardOutlined style={sectionIconStyle} />,
        children: [
          {
            title: "Data Onboarding",
            path: "/app/onboarding/data-sources",
            icon: <CloudUploadOutlined style={sectionIconStyle} />,
          },
          {
            title: "Data Pipelining",
            path: null,
            icon: <ApiOutlined style={sectionIconStyle} />,
          },
          {
            title: "Viz Development",
            path: null,
            icon: <VizDevelopmentIcon style={sectionIconStyle} />,
          },
          {
            title: "Report Development",
            path: null,
            icon: <FileAddOutlined style={sectionIconStyle} />,
          },
          {
            title: "Extraction",
            path: null,
            icon: <CloudDownloadOutlined style={sectionIconStyle} />,
          },
        ],
      },
      {
        title: "Intelligence",
        path: null,
        icon: <SyncOutlined style={sectionIconStyle} />,
        children: [
          {
            title: "Calibration",
            path: "/app/onboarding/calibration",
            icon: <SettingOutlined style={sectionIconStyle} />,
          },
          {
            title: "Data Unification",
            path: null,
            icon: <DeploymentUnitOutlined style={sectionIconStyle} />,
          },
          {
            title: "Integrity",
            path: "/app/integrity",
            icon: <DashboardOutlined style={sectionIconStyle} />,
          },
        ],
      },
      {
        title: "Intervention",
        path: null,
        icon: <RocketOutlined style={sectionIconStyle} />,
        children: [
          {
            title: "Reporting",
            path: report_v2_link,
            icon: <ExceptionOutlined style={sectionIconStyle} />,
          },
          {
            title: "Commenting",
            path: "/app/commenting",
            icon: <CommentIcon style={sectionIconStyle} />,
          },
          {
            title: "Task Management",
            path: "/app/tasks",
            icon: <ProfileOutlined style={sectionIconStyle} />,
          },
          {
            title: "Library",
            path: "/app/library",
            icon: <ReadOutlined style={sectionIconStyle} />,
          },
        ],
      },
    ],
  },
];

const sections = [
  {
    items: [
      {
        title: "Home",
        path: home_v2_link,
        topLevelItem: true,
        icon: <HomeOutlined style={sectionIconStyle} />,
      },
      {
        title: "Monitor",
        path: "/app/monitoring",
        icon: <DashboardOutlined style={sectionIconStyle} />,
        isNonDemoOrg: true,
      },
      {
        title: "Discover",
        path: "/app/discovery",
        icon: <BulbOutlined style={sectionIconStyle} />,
        isNonDemoOrg: true,
      },
      {
        title: "Allocate",
        path: "/app/allocate",
        icon: (
          <BranchesOutlined
            style={{ ...sectionIconStyle, transform: "rotate(90deg)" }}
          />
        ),
        isNonDemoOrg: true,
      },
      // {
      //   title: "Data Onboarding",
      //   path: "/app/onboarding/data-sources",
      //   icon: <CloudUploadOutlined style={sectionIconStyle} />,
      //   isNonDemoOrg: true,
      // },
      {
        title: "Calibration",
        path: "/app/onboarding/calibration",
        icon: <SettingOutlined style={sectionIconStyle} />,
        isNonDemoOrg: true,
      },
      {
        title: "Integrity",
        path: "/app/integrity",
        icon: <DashboardOutlined style={sectionIconStyle} />,
        isNonDemoOrg: true,
      },
      {
        title: "Reporting",
        path: report_v2_link,
        icon: <ExceptionOutlined style={sectionIconStyle} />,
        isNonDemoOrg: true,
      },
      {
        title: "Commenting",
        path: "/app/commenting",
        icon: <CommentIcon style={sectionIconStyle} />,
        isNonDemoOrg: true,
      },
      {
        title: "Task Management",
        path: "/app/tasks",
        icon: <ProfileOutlined style={sectionIconStyle} />,
        isNonDemoOrg: true,
      },
      {
        title: "Library",
        path: "/app/library",
        icon: <ReadOutlined style={sectionIconStyle} />,
        isNonDemoOrg: true,
      },
      {
        title: "Experiment",
        path: null,
        icon: <ExperimentOutlined style={sectionIconStyle} />,
        isNonDemoOrg: true,
      },
      {
        title: "Data Pipelining",
        path: null,
        icon: <ApiOutlined style={sectionIconStyle} />,
        isNonDemoOrg: true,
      },
      {
        title: "Viz Development",
        path: null,
        icon: <VizDevelopmentIcon style={sectionIconStyle} />,
        isNonDemoOrg: true,
      },
      {
        title: "Report Development",
        path: null,
        icon: <FileAddOutlined style={sectionIconStyle} />,
        isNonDemoOrg: true,
      },
      {
        title: "Extraction",
        path: null,
        icon: <CloudDownloadOutlined style={sectionIconStyle} />,
        isNonDemoOrg: true,
      },
      {
        title: "Data Unification",
        path: null,
        icon: <DeploymentUnitOutlined style={sectionIconStyle} />,
        isNonDemoOrg: true,
      },
    ],
  },
];
const drawerWidth = 280;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    backgroundColor: "background.paper",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: "86px",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    padding: "12px",
    justifyContent: "center",
    marginTop: "8px",
    marginBottom: "12px",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

const DashboardSidebar = (props) => {
  const { onMobileClose, openMobile } = props;
  const location = useLocation();
  const { user } = useAuth();
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));

  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [orgDetails, setOrgDetils] = useState([]);
  const [nonDemoSections, setNonDemoSections] = useState(sections);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const getLogoURL = (_isOpen) => {
    if (_isOpen) {
      return "/static/logo_full.png";
    } else {
      return "/static/logo_icon.png";
    }
  };

  const isExactPath = (itemPath) => {
    return location.pathname
      ? !!matchPath(
          {
            path: itemPath,
            end: itemPath === "/app/" ? true : false,
          },
          location.pathname
        )
      : false;
  };

  const isActive = (title) => {
    const org = window.localStorage.getItem("org");
    if (org) {
      let orgParsed = JSON.parse(org);
      let match = _.find(orgParsed.feature_list, { name: title });

      if (match) {
        return true;
      }
    }

    return false;
  };

  const selectedOrgId = window.localStorage.getItem("selectedOrg");
  console.log(selectedOrgId);

  const gettingStarted = {
    title: "Data Onboarding",
    path: "/app/onboarding",
    topLevelItem: true,
    icon: <PoweroffOutlined style={sectionIconStyle} />,
  };

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const getLogoRedirectURL = () => {
    const org = window.localStorage.getItem("org");
    if (org) {
      let orgParsed = JSON.parse(org);
      if (orgParsed.is_home_v2 == true && orgParsed.home_v2_link != null) {
        return "/app/discovery/";
      }
    }

    return "/app/";
  };

  useEffect(() => {
    const getOrganizationData = async () => {
      const organizationDetils = await getOrgData();
      setOrgDetils(organizationDetils);
      const filteredRoutes =
        sections &&
        sections.length &&
        sections.map((data) => {
          const items = data.items && data.items;

          const filteredItems = organizationDetils?.feature_list.map((data) => {
            const test = _.find(items, {
              title: data.name,
            });
            return test;
          });

          const filteredSections = filteredItems?.filter((data) => {
            return data !== undefined;
          });

          if (filteredSections?.length) {
            for (let index = 0; index <= filteredSections.length; index++) {
              if (index !== 0 && index % 2 === 1) {
                filteredSections.splice(index, 0, { isDivider: true });
              }
            }
          }
          return {
            ...data,
            items: filteredSections,
          };
        });

      if (filteredRoutes && filteredRoutes.length) {
        setNonDemoSections(filteredRoutes);
      }
    };

    getOrganizationData();
  }, []);

  const renderNavLinks =
    orgDetails && orgDetails.is_demo_org ? demoSections : nonDemoSections;

  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <Box style={{ background: "#2F324B" }}>
          <Box style={{ margin: "30px 0 26px 0", textAlign: "center" }}>
            <RouterLink to={getLogoRedirectURL()}>
              <img src={getLogoURL(open)} style={{ height: "35px" }} />
            </RouterLink>
          </Box>
        </Box>
        {!open && (
          <div className={clsx(classes.toolbar)}>
            <IconButton onClick={handleDrawerOpen}>
              <DoubleRightOutlined
                style={{ fontSize: "18px", color: "#7D87AA" }}
              />
            </IconButton>
          </div>
        )}
        {open && (
          <div
            className={clsx(classes.toolbar)}
            style={{ ...(open && { justifyContent: "flex-end" }) }}
          >
            <IconButton onClick={handleDrawerClose}>
              <DoubleLeftOutlined
                style={{ fontSize: "18px", color: "#7D87AA" }}
              />
            </IconButton>
          </div>
        )}
        <Box style={{ paddingLeft: "16px", paddingRight: "16px" }}>
          <Divider
            style={{ color: "#3B415F", marginTop: "0px", marginBottom: "8px" }}
          />
        </Box>

        <Scrollbar
          options={{ suppressScrollX: true }}
          style={{ height: "100%" }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
            }}
          >
            {renderNavLinks.map((section) => (
              <NavSection
                open={open}
                key={section.title}
                pathname={location.pathname}
                handleOpen={handleDrawerOpen}
                {...section}
              />
            ))}
            <Box
              sx={{
                flexGrow: 1,
              }}
            />
            <>
              <Box style={{ marginBottom: open ? "0.5rem" : "0px" }}>
                <NavItem
                  active={isExactPath(gettingStarted.path)}
                  depth={0}
                  icon={gettingStarted.icon}
                  info={gettingStarted.info}
                  key={`getting-started`}
                  path={gettingStarted.path}
                  title={gettingStarted.title}
                  open={open}
                  isTurnedOn={isActive(gettingStarted.title)}
                />
              </Box>
              <Box style={{ paddingLeft: "16px", paddingRight: "16px" }}>
                <Divider
                  style={{
                    color: "#3B415F",
                    marginTop: "8px",
                    marginBottom: "14px",
                  }}
                />
              </Box>

              {open && (
                <>
                  <Box
                    className={clsx(classes.toolbar)}
                    style={{
                      marginBottom: "0px",
                      marginTop: "0px",
                      paddingTop: "0px",
                      paddingBottom: "0px",
                    }}
                  >
                    <Tooltip title="Help" placement="right">
                      <IconButton
                        color="inherit"
                        style={{ color: "rgba(125, 135, 170, 1)" }}
                      >
                        <InfoCircleOutlined
                          style={{
                            color: "rgba(125, 135, 170, 1)",
                            fontSize: "24px",
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                  </Box>
                  {selectedOrgId && parseInt(selectedOrgId) === 1 && (
                    <Box
                      className={clsx(classes.toolbar)}
                      style={{ marginBottom: "0px", paddingBottom: "0px" }}
                    >
                      <NotificationsPopover />
                    </Box>
                  )}
                  <Box className={clsx(classes.toolbar)}>
                    <AccountPopover />
                  </Box>
                </>
              )}
              {!open && (
                <>
                  <Box
                    className={clsx(classes.toolbar)}
                    style={{
                      marginBottom: "0px",
                      marginTop: "8px",
                      paddingTop: "0px",
                      paddingBottom: "0px",
                    }}
                  >
                    <Tooltip title="Help" placement="right">
                      <IconButton
                        color="inherit"
                        style={{ color: "rgba(125, 135, 170, 1)" }}
                      >
                        <InfoCircleOutlined
                          style={{
                            color: "rgba(125, 135, 170, 1)",
                            fontSize: "24px",
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                  </Box>
                  {selectedOrgId && parseInt(selectedOrgId) === 1 && (
                    <Box
                      className={clsx(classes.toolbar)}
                      style={{ marginBottom: "0px", paddingBottom: "0px" }}
                    >
                      <NotificationsPopover />
                    </Box>
                  )}
                  <Box className={clsx(classes.toolbar)}>
                    <AccountPopover />
                  </Box>
                </>
              )}
            </>
          </Box>
        </Scrollbar>
      </Box>
    </Drawer>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default DashboardSidebar;
