import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import AuthGuard from './components/AuthGuard';
import DashboardLayout from './components/dashboard/DashboardLayout';
import GuestGuard from './components/GuestGuard';
import LoadingScreen from './components/LoadingScreen';
import MainLayout from './components/MainLayout';

const Loadable = (Component) => (props) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

// Authentication pages
const Login = Loadable(lazy(() => import('./pages/authentication/Login')));
const PasswordRecovery = Loadable(lazy(() => import('./pages/authentication/PasswordRecovery')));
//const PasswordReset = Loadable(lazy(() => import('./pages/authentication/PasswordReset')));

// Dashboard pages
const Home = Loadable(lazy(() => import('./pages/dashboard/Home')));

//Onboarding Pages
const Onboarding = Loadable(lazy(() => import('./pages/onboarding/Onboarding')));

const DataSources = Loadable(lazy(() => import('./pages/onboarding/DataSources')));
const Calibration = Loadable(lazy(() => import('./pages/onboarding/Calibration')));

// Error pages
const AuthorizationRequired = Loadable(lazy(() => import('./pages/AuthorizationRequired')));
const NotFound = Loadable(lazy(() => import('./pages/NotFound')));
const ServerError = Loadable(lazy(() => import('./pages/ServerError')));

// Data Discovery pages
const DataDiscovery = Loadable(lazy(() => import("./pages/discovery/MainPage")));
const DataDiscoveryRedirect = Loadable(lazy(() => import("./pages/discovery/RedirectPage")));
const VisualizationSelection = Loadable(lazy(() => import("./pages/discovery/VisualizationSelection")));
const VisualizationDetail = Loadable(lazy(() => import("./pages/discovery/VisualizationDetail")));

// Allocate v2 pages
const AllocateMain = Loadable(lazy(() => import("./pages/allocate-v2/MainPage")));
const AllocateTableau = Loadable(lazy(() => import("./pages/allocate/AllocateThread")));
const FrameworkSelection = Loadable(lazy(() => import("./pages/allocate-v2/FrameworkSelection")));
const FrameworkDetail = Loadable(lazy(() => import("./pages/allocate-v2/FrameworkDetail")));
const FrameworkDetail2 = Loadable(lazy(() => import("./pages/allocate-v2/FrameworkDetail2")));

// Reporting pages
const ReportSelection = Loadable(lazy(() => import("./pages/reports/ReportSelection")));
const ReportDetail = Loadable(lazy(() => import("./pages/reports/ReportDetail")));
const ReportDetailTufte = Loadable(lazy(() => import("./pages/reports/ReportDetailTufte")));

// Monitoring
const Monitoring = Loadable(lazy(() => import("./pages/monitoring/Monitoring")));
const MonitoringDetail = Loadable(lazy(() => import("./pages/monitoring/MonitoringDetail")));

// Integrity
const Integrity = Loadable(lazy(() => import("./pages/integrity/Integrity")));
const IntegrityDetail = Loadable(lazy(() => import("./pages/integrity/IntegrityDetail")));

// Presentations
const Presentations = Loadable(lazy(() => import("./pages/presentations/Presentations")));

// Task Management
const Tasks = Loadable(lazy(() => import("./pages/tasks/Tasks")));

// Comments
const Comments = Loadable(lazy(() => import("./pages/comment/Comments")));

// Library
const Library = Loadable(lazy(() => import("./pages/library/Library")));

// Library
const Admin = Loadable(lazy(() => import("./pages/admin/Admin")));

// Allocate
const Allocate = Loadable(lazy(() => import("./pages/allocate/Allocate")));

const routes = [
  {
    path: '*',
    element: <MainLayout />,
    children: [
      // Main Application Routes
      {
        path: 'app',
        element: (
          <AuthGuard>
            <DashboardLayout />
          </AuthGuard>
        ),
        children: [
          {
            path: '/',
            element: <Home />
          },
          {
            path: 'onboarding',
            children: [
              {
                path: '/',
                element: <Onboarding />
              },
              {
                path: '/data-sources',
                element: <DataSources />

              },
              {
                path: '/authorization',
                element: <Calibration />
              },
            ]

          },
          {
            path: 'discovery',
            children: [
              {
                path: '/',
                element: <DataDiscovery />
              },
              {
                path: '/redirect',
                element: <DataDiscoveryRedirect />
              },
              {
                path: ':topicLabel',
                element: <VisualizationSelection />
              },
              {
                path: '/:topicLabel/:visualizationId',
                element: <VisualizationDetail />
              }
            ]
          },
          {
            path: 'reports',
            children: [
              {
                path: '/',
                element: <ReportSelection />
              },
              {
                path: '/standard/:reportId',
                element: <ReportDetail />
              },
              {
                path: '/notebook/:reportId',
                element: <ReportDetailTufte />
              }
            ]
          },
          {
            path: 'monitoring',
            children: [
              {
                path: '/',
                element: <MonitoringDetail />
              },
              // {
              //   path: '/detail',
              //   element: <MonitoringDetail />
              // }
            ]
          },
          {
            path: 'integrity',
            children: [
              {
                path: '/',
                element: <IntegrityDetail />
              },
              // {
              //   path: '/detail',
              //   element: <MonitoringDetail />
              // }
            ]
          },
          {
            path: 'commenting',
            element: <Comments />
          },
          {
            path: 'presentations',
            element: <Presentations />
          },
          {
            path: 'tasks',
            element: <Tasks />
          },
          {
            path: 'library',
            element: <Library />
          },
          {
            path: 'admin',
            element: <Admin />
          },
          {
            path: 'allocate',
            children: [
              {
                path: '/',
                element: <AllocateMain />
              },
              {
                path: '/tableau',
                element: <AllocateTableau />
              },
              {
                path: ':topicLabel',
                element: <FrameworkSelection />
              },
              {
                path: '/:topicLabel/:frameworkId',
                element: <FrameworkDetail />
              },
              {
                path: '/:topicLabel/framework/:frameworkId',
                element: <FrameworkDetail2 />
              }
            ]
          },
          {
            path: "*",
            element: <Navigate to="/app/" />,
          },
        ]
      },

      // Other, with MainLayout instead of DashboardLayout
      {
        path: '/forgot-password',
        element: (
          <GuestGuard>
            <PasswordRecovery />
          </GuestGuard>
        )
      },
      {
        path: '/login',
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        )
      },
      {
        path: '401',
        element: <AuthorizationRequired />
      },
      {
        path: '404',
        element: <NotFound />
      },
      {
        path: '500',
        element: <ServerError />
      },
      {
        path: "/app/*",
        element: <Navigate to="/app/" />,
      },
    ]
  }
];

export default routes