import axios from "axios";

const apiURL = process.env.REACT_APP_API_URL || "http://localhost:8000/";

const apiService = axios.create({
  baseURL: apiURL,
  responseType: "json",
  withCredentials: true,
});

apiService.interceptors.request.use(
  (config) => {
    const accessToken = window.localStorage.getItem("access");
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

apiService.interceptors.request.use((config) => {
  const sessionOrg = window.localStorage.getItem("org");

  if (sessionOrg) {
    let _currentOrg = JSON.parse(sessionOrg);
    let orgId = _currentOrg.id;
    let attachOrgId = false;
    if (
      config.url.includes("visualization/") ||
      config.url.includes("/report") ||
      config.url.includes("/organization") ||
      config.url.includes("/ashore") ||
      config.url.includes("/bubble_report_list")
    ) {
      attachOrgId = true;
    }
    config.params = {
      ...config.params,
      organization_id: orgId || attachOrgId ? orgId : 1,
    };
    return config;
  }

  return config;
});

apiService.interceptors.response.use(
  (response) => {
    return response;
  },
  (err) => {
    if (
      err.response &&
      (err.response.status === 401 || err.response.status === 500)
    ) {
      // Try Refresh Token (TBD)
      // If that fails, redirect to login and clear all session data
      // window.localStorage.clear();
      // console.log("location", window.location.pathname);
      // if (window.location.pathname !== '/login') {
      //   window.location.href = '/login';
      // }
    } else {
      return Promise.reject(err);
    }
  }
);

// Axios Instance
export default apiService;

export { apiURL };
