import merge from "lodash/merge";
import { createTheme, responsiveFontSizes } from "@material-ui/core/styles";
import { THEMES } from "../constants";
import { lightShadows, darkShadows } from "./shadows";

const baseOptions = {
  direction: "ltr",
  components: {
    MuiAvatar: {
      styleOverrides: {
        fallback: {
          height: "75%",
          width: "75%",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          color: "#FFFFFF",
          fontFamily:
            'SFProDisplay, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
          fontWeight: 600,
          padding: "8px 14px 10px 14px",
          fontSize: "14px",
          lineHeight: "24px",
          borderRadius: "8px",
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        "*": {
          boxSizing: "border-box",
        },
        html: {
          MozOsxFontSmoothing: "grayscale",
          WebkitFontSmoothing: "antialiased",
          height: "100%",
          width: "100%",
        },
        body: {
          height: "100%",
        },
        "#root": {
          height: "100%",
        },
        "#nprogress .bar": {
          zIndex: "2000 !important",
        },
      },
    },
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: {
          variant: "h6",
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          borderRadius: 3,
          overflow: "hidden",
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        menuIcon: {
          visibility: "visible !important",
        },
      },
    },
    MuiGridFilterForm: {
      styleOverrides: {
        root: {
          backgroundColor: "#2F54EB",
          borderRadius: "8px",
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: "auto",
          marginRight: "16px",
          color: "rgba(125, 135, 170, 1)",
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          color: "#FFF",
          textAlign: "center",
          fontSize: "14px",
          lineHeight: "22px",
          backgroundColor: "#2F324B",
          padding: "8px 16px",
          boxShadow: "0px 8px 16px rgba(31, 34, 52, 0.6)",
          borderRadius: "16px",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: "none",
        },
      },
    },
    MuiStepConnector: {
      styleOverrides: {
        line: {
          borderTop: "2px solid #2F324B",
          width: "50%",
          margin: "0 auto",
        },
        root: {
          "&.Mui-active": {
            "& .MuiStepConnector-line": {
              borderColor: "#2F54EB",
            },
          },
          "&.Mui-completed": {
            "& .MuiStepConnector-line": {
              borderColor: "#2F54EB",
            },
          },
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          color: "#2F324B;",
          fontSize: "32px",
          position: "relative",
          zIndex: "10",
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          color: "#565c80",
          fontWeight: 500,
        },
        today: {
          "&:not(.Mui-selected)": {
            borderColor: "#A3ACCE",
          },
        },
      },
    },
    // MuiTabs: {
    //   styleOverrides: {
    //     root: {
    //       fontFamily: 'SFProDisplay !important',
    //     },
    //     indicator: {
    //       backgroundColor: '#2F54EB',
    //     },
    //   },
    // },
    MuiBreadcrumbs: {
      styleOverrides: {
        root: {},
      },
    },
    MuiTreeItem: {
      styleOverrides: {
        root: {
          color: "#C8CEE5 !important",
          padding: "4px 0px 4px 0px",
          borderRadius: "8px",
          backgroundColor: "#3B415F",
          "&.Mui-focused": {
            backgroundColor: "#3B415F",
          },
          "&:focus": {
            backgroundColor: "#3B415F",
          },
          "&:hover": {
            backgroundColor: "#3B415F",
          },
        },
        content: {
          "&:hover": {
            backgroundColor: "#3B415F",
          },
          padding: "12px 8px",
          "&.Mui-selected": {
            padding: "12px 16px 12px 16px",
            borderBottom: "1px solid transparent",
            borderRadius: "8px",
            backgroundColor: "#3B415F",
            "&.Mui-focused": {
              backgroundColor: "#3B415F",
            },
            "&:focus": {
              backgroundColor: "#3B415F",
            },
            "&:hover": {
              backgroundColor: "#3B415F",
            },
          },
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        iconContainer: {
          paddingRight: "0",
          marginRight: "8px",
          position: "relative",
          "&::before": {
            content: '""',
            position: "absolute",
            top: "2px",
            left: "2px",
            right: "2px",
            bottom: "2px",
            borderRadius: "50%",
            background: "white",
            zIndex: "0",
          },
        },
        labelContainer: {
          color: "#2F324B",
          maxWidth: "275px",
        },

        label: {
          color: "#A3ACCE",
          fontSize: "1.2rem",

          "&.Mui-active": {
            color: "#FFFFFF",
          },
          "&.Mui-completed": {
            color: "#565C80",
          },
        },
      },
    },
  },
  MuiButtonBase: {
    styleOverrides: {
      root: {
        fontFamily: "SFProDisplay",
        fontWeight: 600,
      },
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      root: {
        color: '#FFF'
      }
    }
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        display: "none",
      },
    },
  },

  typography: {
    button: {
      fontWeight: 600,
    },
    fontFamily:
      'SFProDisplay, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
    h1: {
      fontFamily: "SFProDisplay, Helvetica, Arial, sans-serif",
      fontWeight: 700,
      fontSize: "3.5rem",
      color: "#E7EBF9",
    },
    h2: {
      fontFamily: "SFProDisplay, Helvetica, Arial, sans-serif",
      fontWeight: 600,
      fontSize: "2.5rem",
      color: "#E7EBF9",
    },
    h3: {
      fontWeight: 600,
      fontFamily: "SFProDisplay, Helvetica, Arial, sans-serif",
      fontSize: "2.25rem",
      color: "#E7EBF9",
    },
    h4: {
      fontFamily: "SFProDisplay, Helvetica, Arial, sans-serif",
      fontWeight: 600,
      fontSize: "2rem",
      color: "#E7EBF9",
    },
    h5: {
      fontFamily: "SFProDisplay, Helvetica, Arial, sans-serif",
      fontWeight: 600,
      fontSize: "1.5rem",
      color: "#E7EBF9",
    },
    h6: {
      fontFamily: "SFProDisplay, Helvetica, Arial, sans-serif",
      fontWeight: 600,
      fontSize: "1.125rem",
      color: "#E7EBF9",
    },
    subtitle1: {
      fontSize: "1.2rem",
      color: "#A3ACCE",
    },
    subtitle2: {
      color: "#A3ACCE",
      fontSize: "1rem",
      fontWeight: 600,
    },
    overline: {
      fontWeight: 600,
    },
  },
};

const themesOptions = {
  [THEMES.LIGHT]: {
    components: {
      MuiInputBase: {
        styleOverrides: {
          input: {
            "&::placeholder": {
              opacity: 0.86,
              color: "#42526e",
            },
          },
        },
      },
    },
    palette: {
      action: {
        active: "#6b778c",
      },
      background: {
        default: "#E7EBF9",
        paper: "#ffffff",
      },
      error: {
        contrastText: "#ffffff",
        main: "#f44336",
      },
      mode: "light",
      primary: {
        contrastText: "#ffffff",
        main: "#5664d2",
      },
      success: {
        contrastText: "#ffffff",
        main: "#4caf50",
      },
      text: {
        primary: "#172b4d",
        secondary: "#6b778c",
      },
      warning: {
        contrastText: "#ffffff",
        main: "#ff9800",
      },
    },
    shadows: lightShadows,
  },
  [THEMES.DARK]: {
    components: {
      MuiTableCell: {
        styleOverrides: {
          root: {
            borderBottom: "1px solid rgba(145, 158, 171, 0.24)",
          },
        },
      },
    },
    palette: {
      background: {
        default: "#1D2033",
        paper: "#22273E",
      },
      divider: "rgba(145, 158, 171, 0.24)",
      error: {
        contrastText: "#ffffff",
        main: "#f44336",
      },
      mode: "dark",
      primary: {
        contrastText: "#ffffff",
        main: "#2F54EB",
      },
      secondary: {
        contrastText: "#FFFFFF",
        main: "#2F54EB",
      },
      grey: {
        eight: "#2F324B",
        contrastText: "#fff",
      },
      success: {
        contrastText: "#ffffff",
        main: "#4caf50",
      },
      text: {
        primary: "#E7EBF9",
        secondary: "#7D87AA",
        tertiary: "#A3ACCE",
        contrast: "#FFFFFF",
      },
      warning: {
        contrastText: "#ffffff",
        main: "#ff9800",
      },
      info: {
        main: '#597EF7',
        secondary: "#597EF7",
        tertiary: "#597EF7",
        contrast: "#597EF7",
        contrastText: '#597EF7'
      }
    },
    shadows: darkShadows,
  },
  [THEMES.NATURE]: {
    components: {
      MuiTableCell: {
        styleOverrides: {
          root: {
            borderBottom: "1px solid rgba(145, 158, 171, 0.24)",
          },
        },
      },
    },
    palette: {
      background: {
        default: "#1c2531",
        paper: "#293142",
      },
      divider: "rgba(145, 158, 171, 0.24)",
      error: {
        contrastText: "#ffffff",
        main: "#f44336",
      },
      mode: "dark",
      primary: {
        contrastText: "#ffffff",
        main: "#01ab56",
      },
      success: {
        contrastText: "#ffffff",
        main: "#4caf50",
      },
      text: {
        primary: "#ffffff",
        secondary: "#919eab",
      },
      warning: {
        contrastText: "#ffffff",
        main: "#ff9800",
      },
    },
    shadows: darkShadows,
  },
};

export const createCustomTheme = (config = {}) => {
  let themeOptions = themesOptions[config.theme];

  if (!themeOptions) {
    console.warn(new Error(`The theme ${config.theme} is not valid`));
    themeOptions = themesOptions[THEMES.LIGHT];
  }

  let theme = createTheme(
    merge(
      {},
      baseOptions,
      themeOptions,
      {
        ...(config.roundedCorners && {
          shape: {
            borderRadius: 16,
          },
        }),
      },
      {
        direction: config.direction,
      }
    )
  );

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
