import { useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { experimentalStyled } from '@material-ui/core/styles';
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';

const DashboardLayoutRoot = experimentalStyled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  height: '100%',
  overflow: 'hidden',
  width: '100%',
  paddingTop: '0px !important'
}));

const DashboardLayoutWrapper = experimentalStyled('div')(({ theme }) => ({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',
  paddingTop: '0px'
}));

const DashboardLayoutContainer = experimentalStyled('div')({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden'
});

const DashboardLayoutContent = experimentalStyled('div')({
  flex: '1 1 auto',
  height: '100%',
  overflow: 'auto',
  position: 'relative',
  WebkitOverflowScrolling: 'touch'
});

const DashboardLayout = () => {

  const [isSidebarMobileOpen, setIsSidebarMobileOpen] = useState(false);

  const location = useLocation();
  const pathWithToolbar = '/app/reports/';
  // Fixing the reports page styling.
  let contentStyles = {
    // top: (location?.pathname?.includes(pathWithToolbar)) ? '80px' : '0px'
    top: (location?.pathname?.includes(pathWithToolbar)) ? '0px' : '0px'
  };
  let containerStyles = { };
  // Fixing the reports page styling.
  // if (location?.pathname?.includes(pathWithToolbar)) {
  //   contentStyles.backgroundColor = '#E7EBF9';
  //   containerStyles.backgroundColor = '#E7EBF9';
  // }

  return (
    <DashboardLayoutRoot>
      <DashboardSidebar
        onMobileClose={() => setIsSidebarMobileOpen(false)}
        openMobile={isSidebarMobileOpen}
      />
      <DashboardLayoutWrapper>
        <DashboardLayoutContainer style={containerStyles}>
          <DashboardLayoutContent style={contentStyles}>
            <Outlet />
          </DashboardLayoutContent>
        </DashboardLayoutContainer>
      </DashboardLayoutWrapper>
    </DashboardLayoutRoot>
  );
};

export default DashboardLayout;
