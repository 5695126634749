import { useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Box, Card, CardContent, Container, Link, Typography } from '@material-ui/core';
import {
  // LoginJWT
  LoginAuth0
} from '../../components/authentication/login';
import Logo from '../../components/Logo';
import useAuth from '../../hooks/useAuth';
import gtm from '../../lib/gtm';
import { textAlign } from '@material-ui/system';
const Login = () => {

  const { platform } = useAuth();
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const message = params.get("message");

  return (
    <>
      <Helmet>
        {/* <title>Coherence AI - Login</title> */}
        <title>Coherence AI</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh'
        }}
      >
        <Container
          maxWidth="sm"
          sx={{ py: '80px' }}
        >
          <Card>
            <CardContent
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',

                p: 4
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  mb: 2,
                  mt: 2
                }}>
                <img alt="logo" src="/static/logo_white.svg" style={{ maxHeight: '200px' }}/>
              </Box>
              <Box style={{ width: '50%', textAlign: 'center'}}>
                <Typography color="textPrimary">
                  {'Revenue Transformation Intelligence'}
                </Typography>
              </Box>
              <Box
                sx={{
                  flexGrow: 1,
                  mt: 3
                }}
              >
                {/* {platform === 'JWT' && <LoginJWT />} */}
                {platform === 'Auth0' && <LoginAuth0 />}
              </Box>
              {/* <Link
                color="textSecondary"
                component={RouterLink}
                sx={{ mt: 1 }}
                to="/forgot-password"
                variant="body2"
              >
                Forgot Password
              </Link> */}
              <Box style={{ width: '100%', textAlign: 'center'}}>
                <br />
                <Typography color="textSecondary">
                  {message == 'You can now login to the application with the new password.'?'You can now login to the application with your new password.': ''}
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  );
};

export default Login;
