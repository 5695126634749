import { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { Auth0Client } from '@auth0/auth0-spa-js';
import { auth0Config } from '../config';
import API from "../services/API";
import { ClosedCaptionDisabledSharp } from '@material-ui/icons';
import { getOrgData } from '../utils/helpers';

let auth0Client = null;

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

const getUserData = async (access) => {
  const requestConfig = {
    headers: { Authorization: `Bearer ${access}` },
  };
  const userDetailResponse = await API.get("auth/detail/", requestConfig);
  const placeholderUserData = {
    avatar: "/static/mock-images/avatars/avatar-jane_rotanson.png",
    plan: "Premium",
    name: `${userDetailResponse?.data?.first_name} ${userDetailResponse?.data?.last_name}`,
  };
  return {
    ...userDetailResponse.data,
    ...placeholderUserData,
  };
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user, org } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
      org
    };
  },
  LOGIN: (state, action) => {
    const { user, org } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
      org
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
    org: null
  })
};

const reducer = (state, action) => (handlers[action.type]
  ? handlers[action.type](state, action)
  : state);

const AuthContext = createContext({
  ...initialState,
  platform: 'Auth0',
  loginWithPopup: () => Promise.resolve(),
  logout: () => Promise.resolve()
});

export const AuthProvider = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        auth0Client = new Auth0Client({
          redirect_uri: process.env.REACT_APP_AUTH0_REDIRECT_URI,
          cacheLocation: 'localstorage',
          ...auth0Config
        });

        await auth0Client.checkSession();

        const isAuthenticated = await auth0Client.isAuthenticated();

        if (isAuthenticated) {
          const user = window.localStorage.getItem("user");
          let _userData = null;
          let _orgData = null;

          if (!user) {
    
            // If no user, retrieve from backend with access token
            const accessToken = window.localStorage.getItem("access");
            if (!accessToken) {
              // Force a new login
              console.log("NO ACCESS TOKEN");
              localStorage.clear(); // TODO, delete correct keys - access, refresh, user
              dispatch({ type: "LOGOUT" });
            } else {
              let userDataAccess = await getUserData(accessToken);
              console.log("USER DATA ACCESS", userDataAccess);
              if (!userDataAccess) {
                localStorage.clear();
                dispatch({ type: "LOGOUT" });
              } else {
                _userData = userDataAccess;
              }
            }
          } else {
            _userData = JSON.parse(user);
          }
  
          // By this point, we are either logged out, or we have user data
          // Now, try to get organization data
  
          _orgData = await getOrgData();
  

          // Here you should extract the complete user profile to make it
          // available in your entire app.
          // The auth state only provides basic information.

          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user: _userData,
              org: _orgData
            }
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };

    initialize();
  }, []);

  const loginWithPopup = async (options) => {
    await auth0Client.loginWithPopup(options);

    const isAuthenticated = await auth0Client.isAuthenticated();

    if (isAuthenticated) {
      const user = await auth0Client.getUser();
      const auth0_access_token = await auth0Client.getTokenSilently();
      window.localStorage.setItem("auth0_access_token", auth0_access_token);
      let new_tokens;
      try{
        new_tokens = await API.post('auth/get_django_token/', {
          auth0_token: auth0_access_token
        });
      }
      catch(e){
        console.log(e)
        logout();
        
      }

      const accessToken = new_tokens.data.access
      window.localStorage.setItem("access", accessToken);
      
      let _orgData = await getOrgData();

      if (_orgData && _orgData.length <= 1) {
        // Non-Coherence User. set selectedOrg to single Org
        window.localStorage.setItem("selectedOrg", _orgData[0].id);
      }

      // window.localStorage.setItem("refresh", refresh);
      // Here you should extract the complete user profile to make it available in your entire app.
      // The auth state only provides basic information.
      const djangoUser = await getUserData(accessToken);
      window.localStorage.setItem("user", JSON.stringify(djangoUser));

      dispatch({
        type: 'LOGIN',
        payload: {
          user: djangoUser,
          org: _orgData,
        }
      });
    }
  };

  const logout = () => {
    auth0Client.logout();
    localStorage.clear();
    dispatch({
      type: 'LOGOUT'
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        platform: 'Auth0',
        loginWithPopup,
        logout
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AuthContext;
