import { useState, Children, cloneElement } from "react";
import { NavLink as RouterLink, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Collapse,
  ListItem,
  ListItemIcon,
  Tooltip,
  Menu,
  MenuItem,
} from "@material-ui/core";
import ChevronDownIcon from "../icons/ChevronDown";
import ChevronRightIcon from "../icons/ChevronRight";

const NavItem = (props) => {
  const navigate = useNavigate();

  /**
   * PROPS AND WHAT THEY MEAN
   * active / expanderOpenProp - whether or not the expander for a parent NavItem is open (showing its children)
   * children - child NavItems
   * depth - how far down we are in the parent/child hierarchy
   * icon - icon attribute
   * info - info attribute
   * title - name of this NavItem
   * open - whether or not the entire navigation drawer is open
   * handleDrawerOpen - function passed all the way from DashboardSidebar > NavSection > renderNavItems > reduceChildRoutes > NavItem to open and close the navigation drawer
   */
  const {
    active: expanderOpenProp,
    children,
    depth,
    icon,
    info,
    path,
    title,
    open,
    handleDrawerOpen,
    isTurnedOn,
    isNonDemoOrg,
    ...other
  } = props;
  const [expanderOpen, setExpanderOpen] = useState(expanderOpenProp);
  const handleToggle = () => {
    setExpanderOpen((prevOpen) => !prevOpen);
  };

  let paddingLeft = 16;

  if (depth > 0) {
    paddingLeft = 32 + 8 * depth;
  }

  const activeStyles = {
    color: "text.primary",
    backgroundColor: "secondary.main",
    "& svg": {
      color: "text.primary",
    },
    "&:hover": {
      color: "text.primary",
      backgroundColor: "#2F54EB",
    },
  };

  const transitionStyles = {
    color: "text.primary",
    backgroundColor: "rgba(255, 255, 255, 0.08)",
  };

  const inactiveStyles = {
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.08)",
    },
  };

  // Manage state of (minimized drawer) Menu functionality
  const [anchorEl, setAnchorEl] = useState(null);
  const _open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  if (children) {
    // Hijack MenuItem children to add navigate + close menu functionality
    const childrenWithExtraProp = Children.map(
      children.props.children,
      (child) => {
        const path = child.props.path;
        return cloneElement(child, {
          onClick: () => {
            // For each child MenuItem, first navigate to the path (from child props),
            if (path) {
              navigate(path);
            }

            //then handleClose (from this component) to close out parent menu
            handleClose();
          },
        });
      }
    );

    // Detect whether any children have "active" prop
    const hasActive = children.props.children.find(
      (child) => child.props.active
    );

    return (
      <ListItem
        style={{
          display: open ? "block" : "flex",
          paddingLeft: open ? "22px" : "16px",
          paddingRight: open ? "12px" : "16px",
        }}
      >
        {!open && (
          <>
            <Tooltip title={title} placement="right">
              <Button
                onClick={handleClick}
                sx={{
                  minWidth: "52px",
                  color: "rgba(125, 135, 170, 1) !important",
                  fontWeight: "fontWeightMedium",
                  textTransform: "none",
                  ...inactiveStyles,
                  ...(_open && transitionStyles),
                  ...((expanderOpenProp || hasActive) && activeStyles),
                }}
              >
                <ListItemIcon
                  style={{
                    padding: "8px",
                    width: "22px",
                    color: "rgba(125, 135, 170, 1) !important",
                    fontWeight: "fontWeightMedium",
                    textTransform: "none",
                    ...(expanderOpenProp && activeStyles),
                  }}
                >
                  {icon}
                </ListItemIcon>
              </Button>
            </Tooltip>
            <Menu
              anchorEl={anchorEl}
              open={_open}
              onClose={handleClose}
              paperProps={{
                sx: {
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                },
              }}
              anchorOrigin={{
                vertical: "center",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "center",
                horizontal: "left",
              }}
            >
              <MenuItem disabled>
                {title && typeof title === "string" && title.toUpperCase()}
              </MenuItem>
              {childrenWithExtraProp}
            </Menu>
          </>
        )}
        {open && (
          <>
            <Button
              endIcon={
                !open ? (
                  <ChevronRightIcon fontSize="small" />
                ) : (
                  <ChevronDownIcon fontSize="small" />
                )
              }
              onClick={handleToggle}
              startIcon={icon}
              sx={{
                color: "text.secondary",
                fontWeight: "fontWeightMedium",
                justifyContent: "flex-start",
                pl: `${paddingLeft}px`,
                pr: "8px",
                py: "12px",
                textAlign: "left",
                textTransform: "none",
                fontSize: "17px",
                width: "100%",
              }}
              variant="text"
            >
              <Box sx={{ flexGrow: 1 }}>{title}</Box>
              {info}
            </Button>
            <Collapse in={expanderOpen}>{children}</Collapse>
          </>
        )}
      </ListItem>
    );
  }
  return (
    <ListItem
      style={{
        paddingTop: "8px",
        paddingBottom: "8px",
        paddingRight: open ? "6px" : "16px",
      }}
    >
      {!open && (
        <Tooltip title={title} placement="right">
          <Button
            component={path && RouterLink}
            to={path}
            disabled={!isTurnedOn}
            sx={{
              minWidth: "52px",
              color: "rgba(125, 135, 170, 1) !important",
              fontWeight: "fontWeightMedium",
              textTransform: "none",
              opacity: !isTurnedOn ? 0.2 : 1,
              ...inactiveStyles,
              ...(expanderOpenProp && activeStyles),
            }}
          >
            <ListItemIcon
              style={{
                padding: "8px",
                width: "22px",
                color: "rgba(125, 135, 170, 1) !important",
                fontWeight: "fontWeightMedium",
                textTransform: "none",
                ...(expanderOpenProp && activeStyles),
              }}
            >
              {icon}
            </ListItemIcon>
          </Button>
        </Tooltip>
      )}
      {open && (
        <Button
          component={path && RouterLink}
          disabled={!path || !isTurnedOn}
          startIcon={icon}
          sx={{
            color: "text.secondary",
            fontWeight: "fontWeightMedium",
            alignItems: "center",
            justifyContent: "flex-start",
            fontSize: "17px",
            lineHeight: "24px",
            textAlign: "left",
            py: "13px",
            width: "95%",
            textTransform: "none",
            opacity: !path ? 0.5 : 1,
            ...(expanderOpenProp && activeStyles),
            ...(open && {
              pl: `22px`,
              pr: "12px",
            }),
          }}
          variant="text"
          to={path}
        >
          {open && title}
        </Button>
      )}
    </ListItem>
  );
};

NavItem.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.node,
  depth: PropTypes.number.isRequired,
  icon: PropTypes.node,
  info: PropTypes.node,
  open: PropTypes.bool,
  path: PropTypes.string,
  title: PropTypes.string.isRequired,
};

NavItem.defaultProps = {
  active: false,
  open: false,
};

export default NavItem;
