import { CssBaseline, ThemeProvider } from "@material-ui/core";
import { StyledEngineProvider } from "@material-ui/core/styles";
import LogRocket from "logrocket";
import { useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import { useLocation, useNavigate, useRoutes } from "react-router-dom";
import RTL from "./components/RTL";
import useAuth from "./hooks/useAuth";
import useScrollReset from "./hooks/useScrollReset";
import useSettings from "./hooks/useSettings";
import "./i18n";
import { createCustomTheme } from "./theme";
import { getOrgData, getRouteData, initialRoutes } from "./utils/helpers";

const App = () => {
  const [newRoute, setNewRoute] = useState(initialRoutes);
  const { settings } = useSettings();
  const auth = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(async () => {

    const orgData = await getOrgData()
    const allowedRoutes = orgData && orgData?.feature_list && orgData?.feature_list;
    const mappedRoute = allowedRoutes && getRouteData(allowedRoutes);
    const path = location?.pathname.split("/")[2]
    const routeExist = allowedRoutes?.find((value) => value?.route === path)
    if (routeExist || path === "") {
      setNewRoute(mappedRoute);
    }
    else {
      navigate('/app/')
    }
  }, [location?.pathname]);
  const content = useRoutes(newRoute || initialRoutes);
  useScrollReset();

  const theme = createCustomTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: settings.theme,
  });

  // Logrocket
  if (process.env.NODE_ENV === "production") {
    LogRocket.init("rrx6m8/coherence-beta-app");
    if (auth.user) {
      LogRocket.identify(auth.user.email);
    }
  }

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <RTL direction={settings.direction}>
          <CssBaseline />
          <Toaster position="top-center" />
          {auth.isInitialized && content}
        </RTL>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
