import { useState } from 'react';
import { Box, Button, FormHelperText, Typography } from '@material-ui/core';
import useAuth from '../../../hooks/useAuth';
import useMounted from '../../../hooks/useMounted';

const LoginAuth0 = (props) => {
  const mounted = useMounted();
  const { loginWithPopup } = useAuth();
  const [error, setError] = useState(null);
  const isSafari = /^(?!.*chrome).*safari/i.test(navigator.userAgent);

  const handleLogin = async () => {
    try {
      await loginWithPopup();
    } catch (err) {
      console.error(err);
      if (mounted.current) {
        setError(err.message);
      }
    }
  };

  return (
    <div {...props}>
      {error && (
        <Box sx={{ my: 3 }}>
          <FormHelperText error>
            {error}
          </FormHelperText>
        </Box>
      )}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        <Button
          color="primary"
          onClick={handleLogin}
          variant="contained"
          hidden={isSafari}
        >
          Log In
        </Button>
      </Box>
      <Box style={{ width: '100%', textAlign: 'center'}}>
        <br />
        <Typography color="textPrimary">
          {
            isSafari?'Safari is not supported.':''
          }
        </Typography>
        <Typography color="textSecondary">
          {
            isSafari?"We are working to support Safari in the near future. In the meantime, please login on another browser such as Chrome, Firefox, or Edge.":''
          }
        </Typography>
      </Box>
    </div>
  );
};

export default LoginAuth0;
