
class DataSourcesApi {
    getDataSources() {
        const dataSources = [
            {
                name: 'Google Analytics',
                iconSrc: '/static/icons/google-analytics.svg',
                isActivated: true,
                isSupported: true
                
            },
            {
                name: 'Google AdWords',
                iconSrc: '/static/icons/google-ads.svg',
                isActivated: true,
                isSupported: true
                
            },
            {
                name: 'Facebook Ads',
                iconSrc: '/static/icons/facebook-ads.svg',
                isActivated: true,
                isSupported: true
                
            },     
            {
                name: 'Shopify',
                iconSrc: '/static/icons/shopify.svg',  
                isActivated: true,
                isSupported: true
                
            },
            {
                name: 'Quickbooks',
                iconSrc: '/static/icons/quickbooks.svg',
                isActivated: true,
                isSupported: true                
                
            },
            {
                name: 'Salesforce',
                iconSrc: '/static/icons/salesforce.svg',              
                isActivated: true,
                isSupported: true
                
            },
            {
                name: 'Amplitude',
                iconSrc: '/static/icons/amplitude.svg',                               
                isActivated: false,
                isSupported: false
            },
            {
                name: 'Twitter',
                iconSrc: '/static/icons/twitter.svg',                
                isActivated: false,
                isSupported: false
            },
            {
                name: 'LinkedIn',
                iconSrc: '/static/icons/linkedin.svg',  
                isActivated: false,
                isSupported: false              
            },
            {
                name: 'Instagram',
                iconSrc: '/static/icons/instagram.svg',                
                isActivated: false,
                isSupported: false
            },
            {
                name: 'Bing',
                iconSrc: '/static/icons/bing.svg',                
                isActivated: false,
                isSupported: false
            },
            {
                name: 'Adobe Analytics',
                iconSrc: '/static/icons/adobe-analytics.svg',                
                isActivated: false,
                isSupported: false
            },
            {
                name: 'Yahoo',
                iconSrc: '/static/icons/yahoo.svg',                
                isActivated: false,
                isSupported: false
            },
            {
                name: 'Google Adsense',
                iconSrc: '/static/icons/google-adsense.svg',                
                isActivated: false,
                isSupported: false
            }
        ]
        return Promise.resolve(dataSources);
    }

    getDataCategories() {
        const dataCategories = [
            {
                name: 'Web Analytics',
                iconSrc: '/static/icons/two-tone-diff.svg'
            },
            {
                name: 'Marketing',
                iconSrc: '/static/icons/two-tone-diff.svg'
            },
            {
                name: 'Marketing',
                iconSrc: '/static/icons/two-tone-diff.svg'
            },
            {
                name: 'CRM',
                iconSrc: '/static/icons/two-tone-diff.svg'
            },
            {
                name: 'E-commerce',
                iconSrc: '/static/icons/two-tone-diff.svg'
            },
            {
                name: 'Financials',
                iconSrc: '/static/icons/two-tone-diff.svg'
            },
            {
                name: 'Add More',
                iconSrc: '/static/icons/add-more.svg'
            }
        ]

        return Promise.resolve(dataCategories);
    }



}



export const dataSourcesApi =  new DataSourcesApi();