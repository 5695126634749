import { useRef, useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import {
  Box,
  Button,
  ButtonBase,
  Divider,
  Menu,
  Paper,
  ListItemIcon,
  ListItem,
  Dialog,
  DialogTitle,
  Avatar,
  ListItemText,
  MenuItem,
  Popover,
  Typography,
  ListItemAvatar,
  List,
  Tooltip,
  MenuList
} from '@material-ui/core';
import useAuth from '../../hooks/useAuth';
import API from '../../services/API';
import CogIcon from '../../icons/Cog';
import Check from '../../icons/Check';
import Star from '../../icons/Star';
import UserIcon from '../../icons/User';
import SwapHorizontalCircleIcon from '@material-ui/icons/SwapHorizontalCircle';
import { BellOutlined, InfoCircleOutlined, UserOutlined, SettingOutlined, RightOutlined, SwapOutlined } from '@ant-design/icons';

const AccountPopover = () => {

  const anchorRef = useRef(null);

  const { user, logout, org } = useAuth();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      handleClose();
      await logout();
      navigate('/');
    } catch (err) {
      console.error(err);
      toast.error('Unable to logout.');
    }
  };

  const [orgs, setOrgs] = useState(null);

  const getCurrentOrgData = () => {

    if (!orgs) {
      return {};
    }

    if (window.localStorage.getItem('selectedOrg')) {
      if (orgs.length > 1) {
        // Coherence User
        return orgs.find(org => org?.id == window.localStorage.getItem('selectedOrg'));
      } else {
        // Guest
        return orgs[0];
      }
    } else {
      return orgs[0];
    }

  }

  useEffect(() => {

    API.get('auth/organization/?limit=50').then(resp => {

      if (resp && resp.data && resp.data.results) {

        // TODO: Replace with response from server.  Also, handle single Org object for non staff users
        if (Array.isArray(resp.data.results)) {
          setOrgs(resp.data.results);
        }
      }
    });

  }, []);

  const getSecondaryText = () => {
    if (user.is_superuser) {
      return "Coherence Admin"
    } else {
      return `${getCurrentOrgData()?.name}: User`;
    }
  }

  const [switchOpen, setSwitchOpen] = useState(false);
  const handleSwitch = () => {
    handleClose();
    setSwitchOpen(true);
  }

  const handleSwitchClose = () => {
    setSwitchOpen(false);
  }

  const handleMenuClick = (e, _org) => {

    console.log("ORG", _org);
    handleClose();
    setSwitchOpen(false);

    // Set 'org' on localStorage to the selected one and reload
    window.localStorage.setItem('selectedOrg', _org?.id);
    window.localStorage.setItem('org', JSON.stringify(_org) );
    window.location.reload();
  }

  return (
    <>
      <Box
        component={ButtonBase}
        onClick={handleOpen}
        ref={anchorRef}
        sx={{
          alignItems: 'center',
          display: 'flex'
        }}
      >
        <Tooltip title="User" placement="right">
          <Avatar
            sx={{
              height: 44,
              width: 44,
              backgroundColor: 'rgba(47, 50, 75, 1)',
              color: 'rgba(255, 255, 255, 1)'
            }}
          >
            <UserOutlined style={{ fontSize: '24px' }} />
          </Avatar>
        </Tooltip>
      </Box>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        keepMounted
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: { width: 240 }
        }}
      >
        <Box sx={{ p: 2 }}>
          <Typography
            color="text.primary"
            variant="subtitle2"
          >
            User Profile & Settings
          </Typography>
        </Box>
        <Divider />
        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                {user.first_name ? user.first_name[0] : "M"}
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={user.first_name} secondary={getSecondaryText()} />
          </ListItem>
        </List>
        <Box sx={{ mt: 0 }}>
          {/* <MenuItem
            component={RouterLink}
            to="/dashboard/social/profile"
          >
            <ListItemIcon>
              <UserIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText
              primary={(
                <Typography
                  color="#7D87AA"
                  variant="subtitle2"
                >
                  Profile
                </Typography>
              )}
            />
          </MenuItem> */}
          <MenuItem
            component={RouterLink}
            to="/dashboard/account"
            disabled={true}
          >
            <ListItemIcon>
              <CogIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText
              primary={(
                <Typography
                  color="#7D87AA"
                  variant="subtitle2"
                >
                  Settings
                </Typography>
              )}
            />
          </MenuItem>
          {
            user && user.is_superuser && (
              <MenuItem
                component={RouterLink}
                to="admin"
              >
                <ListItemIcon>
                  <Star fontSize="small" />
                </ListItemIcon>
                <ListItemText
                  primary={(
                    <Typography
                      color="#7D87AA"
                      variant="subtitle2"
                    >
                      Admin Portal
                    </Typography>
                  )}
                />
              </MenuItem>
            )
          }
          <Divider style={{ marginTop: '16px' }} />
          {
            user && user.is_superuser && (
              <ListItem sx={{wordBreak: "break-all"}}>
                {orgs !== null && (
                  <>
                    <Typography
                      color="#7D87AA"
                      variant="subtitle2"
                      style={{ fontSize: '18px', width: '50%' }}
                    >
                      {getCurrentOrgData()?.name}
                    </Typography>
                    <Button size="small" variant="contained" style={{ marginLeft: "auto", marginRight: 0 }} onClick={handleSwitch}>Switch</Button>
                  </>
                )}
              </ListItem>
            )
          }
        </Box>
        <Box sx={{ p: 2 }}>
          <Button
            color="primary"
            fullWidth
            onClick={handleLogout}
            variant="outlined"
          >
            Logout
          </Button>
        </Box>
      </Popover>
      <Dialog onClose={handleSwitchClose} open={switchOpen}>
        <DialogTitle style={{ color: '#FFF', verticalAlign: 'middle' }}>
          <SwapHorizontalCircleIcon style={{ marginRight: '12px', marginBottom: '4px'}}/>
          <span>Switch Customer Account</span>
        </DialogTitle>
        <Divider/>
        <Paper sx={{ width: 320 }}>
          <MenuList dense>
            {orgs && orgs.map((_org, idx) => (
              <MenuItem key={idx} onClick={(e) => handleMenuClick(e, _org)} style={{ paddingLeft: '24px' }}>
                <ListItemText style={{ color: '#C8CEE5'}}>{_org.name}</ListItemText>
                {
                  (getCurrentOrgData()?.id === _org?.id && (
                    <Check style={{ marginRight: '8px' }} />
                  ))
                }
              </MenuItem>
            ))}
          </MenuList>
        </Paper>
      </Dialog>
    </>
  );
};

export default AccountPopover;
