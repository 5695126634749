import { Dialog, DialogContent, DialogContentText } from "@material-ui/core";

const StyledDailog = (props) => {
  const { openStyledDailog, closeStyledDailog, children } = props;
  return (
    <>
      <Dialog
        open={openStyledDailog}
        onClose={closeStyledDailog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {children}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default StyledDailog;
