import { useState, useRef, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import {
  Box,
  Container,
  Grid,
  Stack,
  Typography,
  Divider,
  Card,
  CardContent,
  Drawer,
  MenuItem,
  ListItemText,
  Menu,
  List,
  ListItem,
  ListItemAvatar,
  IconButton,
} from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import useSettings from "../../hooks/useSettings";
import { experimentalStyled } from "@material-ui/core/styles";
import useAuth from "../../hooks/useAuth";
import "./framework.css";
import StyledDailog from "../../components/StyledComponents/StyledModal";
import { InfoOutlined } from "@material-ui/icons";
import BouncingDotsLoader from "../../icons/BouncingLoader";
import { BOOKMARKS, SYSTEM_UPDATES, THREATS, LIST_DATA } from "../../constants";
import API from "../../services/API";

const CalibrationSidebarComponent = experimentalStyled(Drawer)({
  "& .MuiDrawer-paper": {
    width: "300px",
  },
});

const Home = () => {
  const { settings } = useSettings();
  const anchorRef = useRef(null);
  const { user, logout } = useAuth();

  const [openMenu, setOpenMenu] = useState(false);
  const [isReportsAvailable, setIsReportsAvailable] = useState(true);

  const org = window.localStorage.getItem("org");
  let orgParsed = JSON.parse(org);

  const handleMenuClick = () => {};

  const handleMenuOpen = () => {
    setOpenMenu(true);
  };

  const handleMenuClose = () => {
    setOpenMenu(false);
  };

  const getName = () => {
    if (user && user.first_name) {
      return user.first_name;
    } else {
      return "Michael";
    }
  };

  useEffect(() => {
    const orgId = orgParsed.id;
    const getReportDetails = () => {
      API.get(`connectors/airtable_reports/?organization_id=${orgId}`)
        .then((resp) => {
          if (resp && resp.data && resp.data.reports.length) {
            setIsReportsAvailable(true);
          } else {
            setTimeout(() => setIsReportsAvailable(false), 3000);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    };
    getReportDetails();
  }, []);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  if (orgParsed.is_home_v2 == true && orgParsed.home_v2_link != null) {
    // Stop redirection to /app/onboarding
    // if (!isReportsAvailable) {
    //   setTimeout(() => (window.location.href = "/app/onboarding"), 5000);
    // }
    return (
      <div style={{ marginLeft: "5%", marginRight: "5%" }}>
        {/* <iframe src="https://staging-admin.coherenceai.com/session" width="0" height="0" tabindex="-1" class="hidden" style={{display: 'none'}}></iframe> */}
        <iframe
          id="report-iframe"
          src={`${orgParsed.home_v2_link}`}
          style={{
            width: "110%",
            height: "100vh",
            border: "medium none",
            marginLeft: "-5%",
            marginRight: "-5%",
          }}
        ></iframe>
        {/* Removing the redirection info message. */}
        {/* <StyledDailog openStyledDailog={!isReportsAvailable}>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Stack direction={"row"} spacing={2}>
              <InfoOutlined />
              <p>Redirecting to Data onboarding</p>
            </Stack>
            <BouncingDotsLoader />
          </div>
        </StyledDailog> */}
      </div>
    );
  } else {
    return (
      <>
        <Helmet>
          {/* <title>Coherence AI - Home</title> */}
          <title>Coherence AI</title>
        </Helmet>
        <Box
          sx={{
            backgroundColor: "background.default",
            height: "100%",
          }}
        >
          <Container
            fixed
            maxWidth="xl"
            style={{
              paddingLeft: "32px",
              paddingRight: "32px",
              paddingTop: "24px",
              paddingBottom: "24px",
            }}
          >
            <Grid container direction="column">
              <Box style={{ marginBottom: "2.5rem", marginTop: "1.5rem" }}>
                <img src="/static/layers/1.png" style={{ width: "100%" }} />
              </Box>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
              >
                <MenuItem onClick={handleClose}>Explore</MenuItem>
                <MenuItem onClick={handleClose}>Share</MenuItem>
                <MenuItem onClick={handleClose}>Comment</MenuItem>
                <MenuItem onClick={handleClose}>Bookmark</MenuItem>
                <Divider />
                <MenuItem onClick={handleClose}>Archive</MenuItem>
              </Menu>
              <Stack direction="row" spacing={2} style={{ width: "100%" }}>
                <Card
                  item
                  xs={3}
                  style={{ flexGrow: 1, marginRight: "0.5rem" }}
                >
                  <CardContent style={{ padding: "0.5rem" }}>
                    <Grid
                      container
                      justifyContent="space-between"
                      alignItems="center"
                      style={{
                        paddingLeft: "1.2rem",
                        paddingRight: "1.2rem",
                        paddingTop: "1rem",
                      }}
                    >
                      <Typography
                        item
                        style={{ fontWeight: 600, fontSize: "22px" }}
                      >
                        Threats
                      </Typography>
                      <Typography
                        item
                        style={{ color: "#A3ACCE", fontSize: "16px" }}
                      >
                        View All
                      </Typography>
                    </Grid>
                    <List
                      style={{
                        height: "300px",
                        minHeight: "300px",
                        overflowY: "scroll",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <Box style={{ width: "100%" }}>
                        {THREATS.map((threat) => (
                          <ListItem
                            secondaryAction={
                              <IconButton
                                edge="end"
                                aria-label="delete"
                                style={{ marginBottom: "12px" }}
                                onClick={handleClick}
                              >
                                <MoreVertIcon />
                              </IconButton>
                            }
                            sx={{
                              alignItems: "middle",
                              padding: "1rem",
                            }}
                          >
                            <ListItemAvatar>
                              <img
                                src={`/static/layers/1_0.png`}
                                style={{ width: "36px" }}
                              />
                            </ListItemAvatar>
                            <ListItemText>
                              {threat.text.substring(0, 35)}
                              {threat.text.length > 35 ? "..." : ""}
                            </ListItemText>
                          </ListItem>
                        ))}
                      </Box>
                    </List>
                  </CardContent>
                </Card>
                <Card
                  item
                  xs={3}
                  style={{
                    flexGrow: 1,
                    marginLeft: "0.5rem",
                    marginRight: "0.5rem",
                  }}
                >
                  <CardContent style={{ padding: "0.5rem" }}>
                    <Grid
                      container
                      justifyContent="space-between"
                      alignItems="center"
                      style={{
                        paddingLeft: "1.2rem",
                        paddingRight: "1.2rem",
                        paddingTop: "1rem",
                      }}
                    >
                      <Typography
                        item
                        style={{ fontWeight: 600, fontSize: "22px" }}
                      >
                        Bookmarks
                      </Typography>
                      <Typography
                        item
                        style={{ color: "#A3ACCE", fontSize: "16px" }}
                      >
                        View All
                      </Typography>
                    </Grid>
                    <List
                      style={{
                        height: "300px",
                        minHeight: "300px",
                        overflowY: "scroll",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <Box style={{ width: "100%" }}>
                        {BOOKMARKS.map((threat) => (
                          <ListItem
                            secondaryAction={
                              <IconButton
                                edge="end"
                                aria-label="delete"
                                style={{ marginBottom: "12px" }}
                                onClick={handleClick}
                              >
                                <MoreVertIcon />
                              </IconButton>
                            }
                            sx={{
                              alignItems: "middle",
                              padding: "1rem",
                            }}
                          >
                            <ListItemAvatar>
                              <img
                                src={`/static/layers/${threat.icon}`}
                                style={{ width: "36px" }}
                              />
                            </ListItemAvatar>
                            <ListItemText>
                              {threat.title.substring(0, 40)}
                              {threat.title.length > 40 ? "..." : ""}
                            </ListItemText>
                          </ListItem>
                        ))}
                      </Box>
                    </List>
                  </CardContent>
                </Card>
                <Card
                  item
                  xs={3}
                  style={{
                    flexGrow: 1,
                    marginLeft: "0.5rem",
                    marginRight: "0.5rem",
                  }}
                >
                  <CardContent style={{ padding: "0.5rem" }}>
                    <Grid
                      container
                      justifyContent="space-between"
                      alignItems="center"
                      style={{
                        paddingLeft: "1.2rem",
                        paddingRight: "1.2rem",
                        paddingTop: "1rem",
                      }}
                    >
                      <Typography
                        item
                        style={{ fontWeight: 600, fontSize: "22px" }}
                      >
                        System Updates
                      </Typography>
                      <Typography
                        item
                        style={{ color: "#A3ACCE", fontSize: "16px" }}
                      >
                        View All
                      </Typography>
                    </Grid>
                    <List
                      style={{
                        height: "300px",
                        minHeight: "300px",
                        overflowY: "scroll",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <Box style={{ width: "100%" }}>
                        {SYSTEM_UPDATES.map((threat) => (
                          <ListItem
                            secondaryAction={
                              <IconButton
                                edge="end"
                                aria-label="delete"
                                style={{ marginBottom: "12px" }}
                                onClick={handleClick}
                              >
                                <MoreVertIcon />
                              </IconButton>
                            }
                            sx={{
                              alignItems: "middle",
                              padding: "1rem",
                            }}
                          >
                            <ListItemAvatar>
                              <img
                                src={`/static/layers/${threat.icon}`}
                                style={{ width: "36px" }}
                              />
                            </ListItemAvatar>
                            <ListItemText>
                              {threat.title.substring(0, 40)}
                              {threat.title.length > 40 ? "..." : ""}
                            </ListItemText>
                          </ListItem>
                        ))}
                      </Box>
                    </List>
                  </CardContent>
                </Card>
              </Stack>
              <Box>
                <Card
                  item
                  xs={3}
                  style={{
                    flexGrow: 1,
                    marginLeft: "0rem",
                    marginTop: "1.5rem",
                    marginRight: "1rem",
                  }}
                >
                  <CardContent style={{ padding: "2rem" }}>
                    <Grid
                      container
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography
                        item
                        style={{ fontWeight: 600, fontSize: "22px" }}
                      >
                        Roadmap / Release Schedule
                      </Typography>
                      <Typography
                        item
                        style={{ color: "#A3ACCE", fontSize: "16px" }}
                      ></Typography>
                    </Grid>
                    <img
                      src="/static/layers/2.png"
                      style={{
                        marginTop: "8px",
                        marginBottom: "12px",
                        width: "100%",
                      }}
                    />
                    <Box
                      style={{
                        height: "250px",
                        maxHeight: "250px",
                        overflowY: "auto",
                        whitespace: "nowrap",
                        marginTop: "12px",
                      }}
                    >
                      <img
                        src="/static/layers/3.png"
                        style={{ width: "95%" }}
                      />
                    </Box>
                  </CardContent>
                </Card>
              </Box>
            </Grid>
          </Container>
        </Box>
      </>
    );
  }
};
export default Home;
