import Icon from '@ant-design/icons';

const CommentSvg = () => (
    <svg viewBox="0 0 20 20" focusable="false" width="1em" height="1em" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.25895 14.5939C1.14076 12.7089 0.749158 10.4805 1.15768 8.32719C1.56621 6.1739 2.74675 4.2438 4.47764 2.89933C6.20853 1.55486 8.37069 0.888502 10.5581 1.02539C12.7455 1.16227 14.8078 2.09298 16.3575 3.64274C17.9073 5.19251 18.838 7.25472 18.9749 9.44215C19.1118 11.6296 18.4455 13.7917 17.101 15.5226C15.7565 17.2535 13.8264 18.4341 11.6732 18.8426C9.51986 19.2511 7.29149 18.8596 5.40649 17.7414L5.40651 17.7413L2.29808 18.6294C2.16947 18.6662 2.03338 18.6678 1.90391 18.6343C1.77443 18.6007 1.65628 18.5332 1.5617 18.4386C1.46713 18.344 1.39956 18.2259 1.36601 18.0964C1.33246 17.9669 1.33415 17.8308 1.37089 17.7022L2.25901 14.5938L2.25895 14.5939Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
);

const CommentIconComponent = props => <Icon component={CommentSvg} {...props} />;

export default CommentIconComponent;