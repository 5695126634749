import PropTypes from "prop-types";
import { matchPath } from "react-router-dom";
import {
  List,
  ListSubheader,
  MenuItem,
  Divider,
  Typography,
  Box,
} from "@material-ui/core";
import NavItem from "./NavItem";
import _ from "lodash";

const renderNavItems = ({ depth = 0, items, pathname, open, handleOpen }) => {
  return (
    <List disablePadding>
      {items.reduce(
        // eslint-disable-next-line no-use-before-define
        (acc, item) =>
          reduceChildRoutes({
            acc,
            item,
            pathname,
            depth,
            open,
            handleOpen,
          }),
        []
      )}
    </List>
  );
};

const reduceChildRoutes = ({
  acc,
  pathname,
  item,
  depth,
  open,
  handleOpen,
}) => {
  const key = item?.title && `${item.title}-${depth}`;
  const exactMatch =
    item?.path && item.path
      ? !!matchPath(
          {
            path: item?.path && item.path,
            end: item?.path === "/app/" ? true : false,
          },
          pathname
        )
      : false;

  const activeStyles = {
    color: "text.primary",
    backgroundColor: "#2F54EB",
    "& svg": {
      color: "text.primary",
    },
    "&:hover": {
      color: "text.primary",
      backgroundColor: "#2F54EB",
    },
  };

  const isActive = (title) => {
    const org = window.localStorage.getItem("org");
    if (org) {
      let orgParsed = JSON.parse(org);
      let match = _.find(orgParsed.feature_list, { name: title && title });

      if (match) {
        return true;
      }
    }

    return false;
  };

  const handleDrawerOpen = () => {
    if (typeof handleOpen !== "undefined") {
      handleOpen();
    }
  };

  if (item?.isDivider === true) {
    acc.push(
      <Box style={{ paddingLeft: "16px", paddingRight: "16px" }}>
        <Divider
          style={{ color: "#3B415F", marginTop: "10px", marginBottom: "8px" }}
        />
      </Box>
    );
  } else {
    if (item?.children) {
      const partialMatch = item?.path
        ? !!matchPath(
            {
              path: item?.path,
              end: false,
            },
            pathname
          )
        : false;

      acc.push(
        <NavItem
          active={partialMatch}
          depth={depth}
          icon={item?.icon}
          info={item?.info}
          key={key}
          path={item?.path}
          title={item?.title}
          open={open}
          handleDrawerOpen={handleDrawerOpen}
          isTurnedOn={isActive(item?.title)}
        >
          {renderNavItems({
            depth: depth + 1,
            items: item?.children,
            pathname,
            open,
          })}
        </NavItem>
      );
    } else {
      if (open === true || item?.topLevelItem === true || item?.isNonDemoOrg) {
        const newPath =
          item?.isNonDemoOrg && !item?.path ? "/app/" : item?.path;
        acc.push(
          <>
            <NavItem
              active={exactMatch}
              depth={depth}
              icon={item?.icon}
              info={item?.info}
              key={key}
              path={newPath}
              title={item?.title}
              open={open}
              isTurnedOn={isActive(item?.title)}
              isNonDemoOrg={item?.isNonDemoOrg}
            />
          </>
        );
      } else {
        acc.push(
          <MenuItem
            active={exactMatch}
            disabled={!item?.path || isActive(item?.title) === false}
            depth={depth}
            info={item?.info}
            key={key}
            path={item?.path}
            style={{
              padding: "16px",
              color: "#7D87AA",
              opacity: !item?.path || isActive(item?.title) === false ? 0.5 : 1,
              ...(exactMatch && activeStyles),
            }}
            isTurnedOn={isActive(item?.title)}
          >
            {item?.icon} {item?.title}
          </MenuItem>
        );
      }
    }
  }

  return acc;
};

const NavSection = (props) => {
  const { items, pathname, title, open, handleOpen, isDivider, ...other } =
    props;
  return (
    <List
      subheader={
        <ListSubheader
          disableGutters
          disableSticky
          sx={{
            color: "text.primary",
            fontSize: "0.75rem",
            lineHeight: 2.5,
            fontWeight: 700,
            textTransform: "uppercase",
          }}
        >
          {title}
        </ListSubheader>
      }
      {...other}
    >
      {renderNavItems({
        items,
        pathname,
        open: open,
        handleOpen,
      })}
    </List>
  );
};

NavSection.propTypes = {
  items: PropTypes.array,
  pathname: PropTypes.string,
  title: PropTypes.string,
};

export default NavSection;
