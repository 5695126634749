const Logo = (props) => (

  <svg viewBox="0 0 40 40" width="40px" height="40px" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillrule="evenodd" cliprule="evenodd" d="M 8.057 14.421 C 6.752 15.174 6.634 16.353 7.702 17.184 C 7.301 17.199 6.981 17.528 6.981 17.933 L 6.981 26.326 C 6.981 26.34 6.981 26.354 6.981 26.369 L 6.981 26.433 C 6.981 26.534 7.001 26.63 7.037 26.718 C 7.163 27.132 7.503 27.528 8.057 27.847 L 17.003 33.012 C 18.438 33.84 20.764 33.84 22.199 33.012 L 31.145 27.847 C 31.698 27.528 32.038 27.132 32.165 26.718 C 32.201 26.63 32.221 26.534 32.221 26.433 L 32.221 26.369 C 32.221 26.355 32.221 26.34 32.221 26.326 L 32.221 17.933 C 32.221 17.528 31.901 17.199 31.5 17.184 C 32.568 16.353 32.45 15.174 31.145 14.421 L 22.199 9.256 C 20.764 8.428 18.438 8.428 17.003 9.256 L 8.057 14.421 Z" fill="#1D39C4" />
    <rect width="16.33" height="14.141" rx="3" transform="matrix(0.866044, -0.499967, 1.000066, 0.577338, 5.458651, 17.920023)" fill="#22273E" />
    <rect width="16.33" height="14.141" rx="3" transform="matrix(0.866044, -0.499967, 1.000066, 0.577338, 5.458651, 15.919986)" fill="#2F54EB" />
  </svg>

);

export default Logo;
