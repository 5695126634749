// import React from "react"
import { BsClock, BsHash, BsType } from "react-icons/bs";
import arEG from "d3-time-format/locale/ar-EG.json";
import caES from "d3-time-format/locale/ca-ES.json";
import csCZ from "d3-time-format/locale/cs-CZ.json";
import daDK from "d3-time-format/locale/da-DK.json";
import deCH from "d3-time-format/locale/de-CH.json";
import deDE from "d3-time-format/locale/de-DE.json";
import enCA from "d3-time-format/locale/en-CA.json";
import enGB from "d3-time-format/locale/en-GB.json";
import enUS from "d3-time-format/locale/en-US.json";
import esES from "d3-time-format/locale/es-ES.json";
import esMX from "d3-time-format/locale/es-MX.json";
import faIR from "d3-time-format/locale/fa-IR.json";
import fiFI from "d3-time-format/locale/fi-FI.json";
import frCA from "d3-time-format/locale/fr-CA.json";
import frFR from "d3-time-format/locale/fr-FR.json";
import heIL from "d3-time-format/locale/he-IL.json";
import huHU from "d3-time-format/locale/hu-HU.json";
import itIT from "d3-time-format/locale/it-IT.json";
import jaJP from "d3-time-format/locale/ja-JP.json";
import koKR from "d3-time-format/locale/ko-KR.json";
import mkMK from "d3-time-format/locale/mk-MK.json";
import nbNO from "d3-time-format/locale/nb-NO.json";
import nlNL from "d3-time-format/locale/nl-NL.json";
import plPL from "d3-time-format/locale/pl-PL.json";
import ptBR from "d3-time-format/locale/pt-BR.json";
import ruRU from "d3-time-format/locale/ru-RU.json";
import svSE from "d3-time-format/locale/sv-SE.json";
import ukUA from "d3-time-format/locale/uk-UA.json";
import zhCN from "d3-time-format/locale/zh-CN.json";
import zhTW from "d3-time-format/locale/zh-TW.json";

export const THEMES = {
  LIGHT: "LIGHT",
  DARK: "DARK",
  NATURE: "NATURE",
};

// // creplaced with the following
// export const DATATYPE_ICONS = {
//   'date': <BsClock />,
//   'number': <BsHash />,
//   'string': <BsType />
// }

export const DateIcon = BsClock;
export const NumberIcon = BsHash;
export const StringIcon = BsType;

export const dataTypeIcons = {
  date: DateIcon,
  number: NumberIcon,
  string: StringIcon,
};

export const localeList = {
  "ar-EG": arEG,
  "ca-ES": caES,
  "cs-CZ": csCZ,
  "da-DK": daDK,
  "de-CH": deCH,
  "de-DE": deDE,
  "en-CA": enCA,
  "en-GB": enGB,
  "en-US": enUS,
  "es-ES": esES,
  "es-MX": esMX,
  "fa-IR": faIR,
  "fi-FI": fiFI,
  "fr-CA": frCA,
  "fr-FR": frFR,
  "he-IL": heIL,
  "hu-HU": huHU,
  "it-IT": itIT,
  "ja-JP": jaJP,
  "ko-KR": koKR,
  "mk-MK": mkMK,
  "nb-NO": nbNO,
  "nl-NL": nlNL,
  "pl-PL": plPL,
  "pt-BR": ptBR,
  "ru-RU": ruRU,
  "sv-SE": svSE,
  "uk-UA": ukUA,
  "zh-CN": zhCN,
  "zh-TW": zhTW,
};

export const WEBWORKER_ACTIVE = true;

export const separatorsLabels = {
  "\\t": "Tab",
  ";": "Semicolon",
  ",": "Comma",
  "|": "Pipe",
};

export const separatorsList = Object.keys(separatorsLabels);

export const DefaultSeparator = ",";

export const AGGREGATIONS_LABELS = {
  count: "Count",
  mean: "Average",
  median: "Median",
  max: "Max",
  min: "Min",
  countDistinct: "Count unique",
  sum: "Sum",
  csv: "CSV",
  csvDistinct: "CSV (unique)",
};

export const SCALES_LABELS = {
  sequential: "Sequential",
  diverging: "Diverging",
  ordinal: "Ordinal",
};

export const COLOR_SCHEMES_LABELS = {
  interpolateBlues: "Blue sequential",
  interpolateGreens: "Green sequential",
  interpolateReds: "Red sequential",
  interpolateRdBu: "RdBu diverging",
  interpolateBrBG: "BrBG diverging",
  interpolatePiYG: "PiYG diverging",
  schemeCategory10: "Categorical 10",
  interpolateTurbo: "Turbo discrete",
  interpolateSpectral: "Spectral discrete",
};

export const REPORTS = [
  {
    id: 1,
    title: "SaaS Project Management",
    created_at: "2021-09-07T19:29:13.192235Z",
    modified_at: "2021-09-07T19:29:13.192254Z",
    profile: "/static/reports/profiles/mike.jpg",
    description:
      "Engagement Team: Michael Glick, CEO  and  Michael Easton, Head of Data Science.",
    business_topic: {
      id: 3,
      topic_type: "efficiency",
      name: "Operational Efficiency & Unit Economics",
      image_url: null,
    },
    chapters: [
      {
        title: "Executive Summary",
        prezi_embed_code: "l9MpQ3vQFFHjrXQAA7JO",
      },
      {
        title: "Advertising Costs",
        prezi_embed_code: "NwnsQeccTkmN2EaWonMZ",
      },
      {
        title: "Auction Insight",
        prezi_embed_code: "mvzw2JQDtNjzlW1oPOdb",
      },
      {
        title: "Placement Report",
        prezi_embed_code: "0awJjjcavOXrwX65G2bn",
      },
      {
        title: "Settings & Configuration",
        prezi_embed_code: "aT2sMoTZ7x91lkNuyJwB",
      },
    ],
  },
  {
    id: 8,
    title: "Monthly Financial Reporting",
    created_at: "2021-09-07T19:29:13.192235Z",
    modified_at: "2021-09-07T19:29:13.192254Z",
    profile: "/static/reports/profiles/Ben.jpg",
    description:
      "Engagement Team: Michael Glick, CEO  and  Michael Easton, Head of Data Science.",
    business_topic: {
      id: 3,
      topic_type: "efficiency",
      name: "Operational Efficiency & Unit Economics",
      image_url: null,
    },
    chapters: [
      {
        title: "Chapter 1",
        prezi_embed_code: "qWXYuTIwEx2scLb9STNN",
      },
    ],
  },
  {
    id: 3,
    title: "Quarterly Performance Review",
    created_at: "2021-09-07T19:29:13.192235Z",
    modified_at: "2021-09-07T19:29:13.192254Z",
    profile: "/static/reports/profiles/Jaime.jpg",
    description:
      "Engagement Team: Michael Glick, CEO  and  Michael Easton, Head of Data Science.",
    business_topic: {
      id: 3,
      topic_type: "efficiency",
      name: "Operational Efficiency & Unit Economics",
      image_url: null,
    },
    chapters: [
      {
        title: "Chapter 1",
        prezi_embed_code: "qWXYuTIwEx2scLb9STNN",
      },
    ],
  },
  {
    id: 4,
    title: "Presentation Weekly",
    created_at: "2021-09-07T19:29:13.192235Z",
    modified_at: "2021-09-07T19:29:13.192254Z",
    profile: "/static/reports/profiles/Nima.jpg",
    description:
      "Engagement Team: Michael Glick, CEO  and  Michael Easton, Head of Data Science.",
    business_topic: {
      id: 3,
      topic_type: "efficiency",
      name: "Operational Efficiency & Unit Economics",
      image_url: null,
    },
    chapters: [
      {
        title: "Chapter 1",
        prezi_embed_code: "qWXYuTIwEx2scLb9STNN",
      },
    ],
  },
  {
    id: 5,
    title: "Quarterly Performance Review",
    created_at: "2021-09-07T19:29:13.192235Z",
    modified_at: "2021-09-07T19:29:13.192254Z",
    profile: "/static/reports/profiles/Sparsh.jpg",
    description:
      "Engagement Team: Michael Glick, CEO  and  Michael Easton, Head of Data Science.",
    business_topic: {
      id: 3,
      topic_type: "efficiency",
      name: "Operational Efficiency & Unit Economics",
      image_url: null,
    },
    chapters: [
      {
        title: "Chapter 1",
        prezi_embed_code: "qWXYuTIwEx2scLb9STNN",
      },
    ],
  },
];

export const SYSTEM_UPDATES = [
  {
    icon: "3_0.png",
    title: "Customer Journey Strategic Drift Framework analysis completed",
  },
  {
    icon: "3_1.png",
    title: "Sales Enablement Project Plan Created",
  },
  {
    icon: "3_2.png",
    title: "Paid Search Advertising report added ",
  },
  {
    icon: "3_3.png",
    title: "Product Category Revenue Performance ",
  },
  {
    icon: "3_0.png",
    title: "Customer Journey Strategic Drift Framework analysis completed",
  },
  {
    icon: "3_1.png",
    title: "Sales Enablement Project Plan Created",
  },
  {
    icon: "3_2.png",
    title: "Paid Search Advertising report added ",
  },
  {
    icon: "3_3.png",
    title: "Product Category Revenue Performance ",
  },
];

export const BOOKMARKS = [
  {
    icon: "2_0.png",
    title: "Company-wide Threat Assessment",
  },
  {
    icon: "2_1.png",
    title: "Revenue Generation",
  },
  {
    icon: "2_2.png",
    title: "Product SKU Revenue Performance ",
  },
  {
    icon: "2_3.png",
    title: "Product Category Revenue Performance ",
  },
  {
    icon: "2_4.png",
    title: "Customer Acquisition Sources",
  },
  {
    icon: "2_0.png",
    title: "Company-wide Threat Assessment",
  },
  {
    icon: "2_1.png",
    title: "Revenue Generation",
  },
  {
    icon: "2_2.png",
    title: "Product SKU Revenue Performance ",
  },
  {
    icon: "2_3.png",
    title: "Product Category Revenue Performance ",
  },
  {
    icon: "2_4.png",
    title: "Customer Acquisition Sources",
  },
];

export const THREATS = [
  {
    text: "Sales Funnel Conversion Rates Below Forecast",
  },
  {
    text: "Customer Lifetime Contribution Below Forecast",
  },
  {
    text: "Digital Ad Campaign Misconfigurations Resulting in Zero Incremental Revenue",
  },
  {
    text: "Lead Qualification Process Optimizing for the Wrong Objectives",
  },
  {
    text: "Severe Negative Economic Impact From Rapid Churn Accounts",
  },
  {
    text: "Sales Funnel Conversion Rates Below Forecast",
  },
  {
    text: "Customer Lifetime Contribution Below Forecast",
  },
  {
    text: "Digital Ad Campaign Misconfigurations Resulting in Zero Incremental Revenue",
  },
  {
    text: "Lead Qualification Process Optimizing for the Wrong Objectives",
  },
  {
    text: "Severe Negative Economic Impact From Rapid Churn Accounts",
  },
];

export const LIST_DATA = [
  {
    x: "Interventions",
    y: "Sales Enablement Project Plan created",
  },
  {
    x: "Data Discovery Visualization",
    y: "Revenue Explainability - Q3’21 visualization complete",
  },
  {
    x: "Data Onboarding",
    y: "Amazon Advertising was added as a data source",
  },
  {
    x: "Reports",
    y: "Sales Conversion Funnel Report... was updated",
  },
  {
    x: "Reports",
    y: "Investigate - Intervention in the report B2B Electronics Company... was updated",
  },
];

export const DEFAULT_CHAPTER = [
  {
    title: "Executive Summary",
    prezi_embed_code: "l9MpQ3vQFFHjrXQAA7JO",
  },
  {
    title: "Advertising Costs",
    prezi_embed_code: "NwnsQeccTkmN2EaWonMZ",
  },
  {
    title: "Auction Insight",
    prezi_embed_code: "mvzw2JQDtNjzlW1oPOdb",
  },
  {
    title: "Placement Report",
    prezi_embed_code: "0awJjjcavOXrwX65G2bn",
  },
  {
    title: "Settings & Configuration",
    prezi_embed_code: "aT2sMoTZ7x91lkNuyJwB",
  },
];

export const COMMENTS = [
  {
    id: 15,
    created_at: "2021-09-17T01:14:42.125091Z",
    count: 1,
    header_image: "/static/reports/comments/1.png",
    name: "Executive Summary",
    public_id: "3b97f8b3937b4c57be2de91b73bc8e27",
    visualization: 2,
  },
  {
    id: 16,
    created_at: "2021-09-17T01:11:51.702758Z",
    count: 2,
    header_image: "/static/reports/comments/2.png",
    name: "Cost Breakdown",
    public_id: "ff8f58affeff415ca55aa78af3a6fe9b",
    visualization: 2,
  },
  {
    id: 17,
    created_at: "2021-09-17T01:11:51.702758Z",
    count: 3,
    header_image: "/static/reports/comments/3.png",
    name: "Auction Insights",
    public_id: "4586d9cf554d4b77b68d0acdb41027b6",
    visualization: 2,
  },
  {
    id: 18,
    created_at: "2021-09-17T01:11:51.702758Z",
    count: 4,
    header_image: "/static/reports/comments/4.png",
    name: "Media Placements",
    public_id: "1c82d754f14d4fda834d800b55701674",
    visualization: 2,
  },
  {
    id: 19,
    created_at: "2021-09-17T01:11:51.702758Z",
    count: 5,
    header_image: "/static/reports/comments/5.png",
    name: "Campaign Configurations",
    public_id: "3f2756be01394464889d04d41b127cdc",
    visualization: 2,
  },
];
