import { createContext, useEffect, useReducer } from "react";

import PropTypes from "prop-types";

import API from "../services/API";

import { dataSourcesApi } from "../__fakeApi__/dataSourcesApi";

const INITIAL_STATE = {
  isActive: false,
  dataSources: [],
  selectedDataSourceIDs: [],
  dataSourcesToRemove: [],
  revenueAccuracySources: [
    { name: "Google Analytics", logoSrc: "/static/icons/google-analytics.svg" },
    { name: "Shopify", logoSrc: "/static/icons/shopify.svg" },
    { name: "Quickbooks", logoSrc: "/static/icons/quickbooks.svg" },
  ],
  growthTypes: ["Growth Rate (%)", "Growth Multiple(%)", "Target Value($M)"],
  targetCACOptions: ["Reduce CAC", "Maintain CAC", "Increase CAC"],
  targetContributionMarginOptions: [
    "Maintain Current Contribution Margin",
    "Acheive Breakeven Contribution Margin",
    "Acheive Positive Contribution Margin",
  ],
  calibration: {
    id: null,
    activeStep: 0,
    subStep: 1,
    selectedTimePeriod: 0,
    selectedGrowth: 0,
    selectedContributionMargin: 0,
    selectedTargetCAC: 0,
    selectedDateRange: 0,
    selectedRevenueAccuracy: 0,
    selectedRevenueBreakdown: [],
    revenueCurrent: 0,
    targetRevenueNextYear: 0,
    targetRevenueYearAfter: 0,
    cosPercent: 50,
    ovePercent: 50,
    cacPercent: 100,
    targetCMNextYear: 0,
    contribution: 0,
    improveMostNextYear: "",
  },
  connections: [],
};

const handlers = {
  SET_SELECTED_SOURCE: (state, action) => {
    const { selectedDataSource } = action.payload;
    const { id } = selectedDataSource;

    const { selectedDataSourceIDs, dataSourcesToRemove } = state;
    let newSelectedDataSourceIDs;
    let newDataSourcesToRemove = dataSourcesToRemove;

    const includes = selectedDataSourceIDs.some(
      (source) => source.data_source === id
    );

    if (!includes) {
      newDataSourcesToRemove = dataSourcesToRemove.filter(
        (selected) => selected.data_source !== id
      );
      const foundSource = dataSourcesToRemove.filter(
        (selected) => selected.data_source === id
      )[0];
      const newId = foundSource?.id ? foundSource.id : null;

      newSelectedDataSourceIDs = [
        ...selectedDataSourceIDs,
        { id: newId, data_source: id },
      ];
    } else {
      newSelectedDataSourceIDs = selectedDataSourceIDs.filter(
        (selected) => selected.data_source !== id
      );
      const dataSourceToRemove =
        id !== null
          ? selectedDataSourceIDs.filter(
              (selected) => selected.data_source === id && selected.id !== null
            )
          : null;
      newDataSourcesToRemove = [...dataSourcesToRemove, ...dataSourceToRemove];
    }

    return {
      ...state,
      selectedDataSourceIDs: newSelectedDataSourceIDs,
      dataSourcesToRemove: newDataSourcesToRemove,
    };
  },
  SET_SELECTED_DATA_SOURCES: (state, action) => {
    const { selectedDataSourceIDs } = action.payload;

    return {
      ...state,
      selectedDataSourceIDs,
    };
  },
  SET_DATA_SOURCES_TO_REMOVE: (state, action) => {
    const { selectedDataSourceIDs } = action.payload;

    return {
      ...state,
      selectedDataSourceIDs,
    };
  },
  SET_DATA_SOURCES: (state, action) => {
    const { dataSources } = action.payload;

    return {
      ...state,
      dataSources,
    };
  },
  ADD_DATA_SOURCES: (state, action) => {
    const { newDataSources } = action.payload;

    const { dataSources } = state;

    const updatedDataSources = [...dataSources, ...newDataSources];

    return {
      ...state,
      dataSources: updatedDataSources,
    };
  },
  SET_ACTIVATION_STATUS: (state, action) => {
    const { isActive } = action.payload;

    return {
      ...state,
      isActive,
    };
  },
  SET_CALIBRATION_VALUES: (state, action) => {
    const { key, value } = action.payload;
    let calibration = { ...state.calibration };
    if (key === "selectedRevenueBreakdown") {
      let { selectedRevenueBreakdown } = calibration;
      const resultArr = selectedRevenueBreakdown.filter(
        (item) => item !== value
      );

      if (resultArr.length === selectedRevenueBreakdown.length) {
        selectedRevenueBreakdown.push(value);
      } else {
        selectedRevenueBreakdown = resultArr;
      }

      calibration["selectedRevenueBreakdown"] = selectedRevenueBreakdown;
      return {
        ...state,
        calibration,
      };
    }

    calibration[key] = value;
    return {
      ...state,
      calibration,
    };
  },
  SET_CALIBRATION: (state, action) => {
    const { calibration } = action.payload;

    return {
      ...state,
      calibration,
    };
  },
  RESET_CALIBRATION: (state) => {
    const calibration = { ...state.calibration };

    return {
      ...state,
      calibration: {
        ...calibration,
        activeStep: 0,
        subStep: 1,
        revenueCurrent: 0,
        targetRevenueNextYear: 0,
        targetRevenueYearAfter: 0,
        cosPercent: 50,
        ovePercent: 50,
        cacPercent: 100,
        targetCMNextYear: 0,
        contribution: 0,
        improveMostNextYear: "",
      },
    };
  },
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const initialState = INITIAL_STATE;

const DataSourceContext = createContext({
  ...initialState,
});

export const DataSourceProvider = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initializeCalibration = async () => {
      try {
        const calibrationResponse = await API.get(`/connectors/calibration/`);
        const storedCalibration = calibrationResponse.data.results[0]
          ? calibrationResponse.data.results[0].settings
          : null;
        if (storedCalibration) {
          setApiCalibration(storedCalibration);
        }
      } catch (err) {}
    };

    const getDataSources = async () => {
      try {
        const dataSources = await API.get(
          `/connectors/data_source?is_active=true&limit=100`
        );
        dispatch({
          type: "SET_DATA_SOURCES",
          payload: {
            dataSources: dataSources.data.results,
          },
        });
        const moreDataSources = await API.get(
          `/connectors/data_source?is_active=false&limit=322`
        );

        const { data } = moreDataSources;
        const { results } = data;

        const sortedResults = results.sort((a, b) => {
          return a.name.localeCompare(b.name);
        });

        dispatch({
          type: "ADD_DATA_SOURCES",
          payload: {
            newDataSources: sortedResults,
          },
        });
      } catch (err) {
        console.log(err);
      }
    };

    const getSelectedDataSourceIDs = async () => {
      try {
        const selectedDataSourceIDs = await API.get(
          "connectors/connection_request/?limit=322"
        );
        const { data } = selectedDataSourceIDs;
        const { results } = data;
        dispatch({
          type: "SET_SELECTED_DATA_SOURCES",
          payload: {
            selectedDataSourceIDs: results,
          },
        });
      } catch (err) {}
    };
    getDataSources();
    getSelectedDataSourceIDs();
    initializeCalibration();
  }, []);

  const setSelectedDataSource = (selectedDataSource) => {
    dispatch({
      type: "SET_SELECTED_SOURCE",
      payload: {
        selectedDataSource,
      },
    });
  };

  const setActivationStatus = (isActive) => {
    dispatch({
      type: "SET_ACTIVATION_STATUS",
      payload: {
        isActive,
      },
    });
  };

  const setCalibration = (key, value) => {
    dispatch({
      type: "SET_CALIBRATION_VALUES",
      payload: {
        key,
        value,
      },
    });
  };
  const setApiCalibration = (calibration) => {
    dispatch({
      type: "SET_CALIBRATION",
      payload: {
        calibration,
      },
    });
  };
  const resetCalibration = () => {
    dispatch({
      type: "RESET_CALIBRATION",
    });
  };

  const createCalibration = async (calibration) => {
    const { activeStep } = calibration;

    let newStep = activeStep + 1;
    calibration["activeStep"] = newStep;
    const requestData = {
      settings: calibration,
    };
    const calibrationResponse = await API.post(
      "connectors/calibration/",
      requestData
    );
    const { id, settings } = calibrationResponse.data;
    settings.id = id;
    if (settings) {
      setApiCalibration(settings);
    }
  };

  const updateCalibration = async (calibration) => {
    const { activeStep } = calibration;

    let newStep = activeStep + 1;
    calibration["activeStep"] = newStep;
    const requestData = {
      settings: calibration,
    };
    const calibrationResponse = await API.put(
      `connectors/calibration/${calibration.id}/`,
      requestData
    );
    const { settings } = calibrationResponse.data;

    if (settings) {
      setApiCalibration(settings);
    }
  };

  const createConnection = async (
    selectedDataSourceIDs,
    dataSourcesToDelete
  ) => {
    for (let index = 0; index < selectedDataSourceIDs.length; index++) {
      if (selectedDataSourceIDs[index].id === null) {
        const requestData = {
          data_source: selectedDataSourceIDs[index].data_source,
        };
        const connectionResponse = await API.post(
          "connectors/connection_request/",
          requestData
        );
        const { data } = connectionResponse;
        selectedDataSourceIDs[index].id = data.id;
      }
    }

    for (let index = 0; index < dataSourcesToDelete.length; index++) {
      if (dataSourcesToDelete[index].id !== null) {
        const connectionResponse = await API.delete(
          `/connectors/connection_request/${dataSourcesToDelete[index].id}/`
        );
        dataSourcesToDelete.splice(index, 1);
      }
    }

    dispatch({
      type: "SET_DATA_SOURCES_TO_REMOVE",
      payload: { dataSourceToRemove: [] },
    });

    dispatch({
      type: "SET_SELECTED_DATA_SOURCES",
      payload: {
        selectedDataSourceIDs,
      },
    });
  };

  const removeConnection = async (id) => {
    try {
      await API.delete(`/connectors/connection_request/${id}/`);
      dispatch({
        type: "REMOVE_SELECTED_SOURCE",
        payload: {
          id,
        },
      });
    } catch (err) {}
  };

  const getSelectedDataSourceIDs = async () => {
    try {
      const selectedDataSourceIDs = await API.get(
        "connectors/connection_request/?limit=322"
      );
      const { data } = selectedDataSourceIDs;
      const { results } = data;
      dispatch({
        type: "SET_SELECTED_DATA_SOURCES",
        payload: {
          selectedDataSourceIDs: results,
        },
      });
    } catch (err) {}
  };

  const getAuthorizedConnection = async () => {
    try {
      const selectedDataSourceIDs = await API.get(
        "connectors/get_authorization_url/"
      );
      const { data } = selectedDataSourceIDs;
      const { results } = data;
      dispatch({
        type: "SET_SELECTED_DATA_SOURCES",
        payload: {
          selectedDataSourceIDs: results,
        },
      });
    } catch (err) {}
  };

  return (
    <DataSourceContext.Provider
      value={{
        ...state,
        setSelectedDataSource,
        setActivationStatus,
        setCalibration,
        resetCalibration,
        createCalibration,
        setApiCalibration,
        updateCalibration,
        createConnection,
        removeConnection,
        getSelectedDataSourceIDs,
      }}
    >
      {children}
    </DataSourceContext.Provider>
  );
};

DataSourceProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DataSourceContext;
