import { Navigate } from "react-router";
import routes from "../routes";
import API from "../services/API";
import Home from "../pages/dashboard/Home";
import MainLayout from "../components/MainLayout";
import AuthGuard from "../components/AuthGuard";
import DashboardLayout from "../components/dashboard/DashboardLayout";
import GuestGuard from "../components/GuestGuard";
import PasswordRecovery from "../pages/authentication/PasswordRecovery";
import Login from "../pages/authentication/Login";
import AuthorizationRequired from "../pages/AuthorizationRequired";
import NotFound from "../pages/NotFound";
import ServerError from "../pages/ServerError";
import { Fade, Typography } from "@material-ui/core";

export const getRouteData = (allowedRoutes) => {
  const mappedRoute = routes?.map((childRoute) => ({
    ...childRoute,
    children:
      childRoute.children.length &&
      childRoute.children?.map((child) => ({
        ...child,
        children:
          child?.children?.length &&
          child?.children.filter((childr) => {
            return allowedRoutes.some((item2) => {
              if (childr.path === "/") {
                return {
                  path: "/",
                  element: <Home />,
                };
              }
              return childr.path?.toLowerCase() === item2.route?.toLowerCase();
            });
          }),
      })),
  }));
  return mappedRoute;
};

export const getOrgData = async () => {
  let _orgData = null;
  let orgData = await API.get("auth/organization/?limit=50");

  if (
    orgData &&
    orgData.data &&
    orgData.data.results &&
    orgData.data.results.length > 1
  ) {
    let selectedOrg = window.localStorage.getItem("selectedOrg");
    if (!selectedOrg) {
      selectedOrg = 1;
      window.localStorage.setItem("selectedOrg", selectedOrg);
    }

    // Coherence Admin Users who get returned an array
    let selectedOrgId = selectedOrg;

    for (let individualOrg of orgData.data.results) {
      if (parseInt(individualOrg.id) === parseInt(selectedOrgId)) {
        _orgData = individualOrg;
        break;
      }
    }

    console.log(
      "SELECTED ORG ID",
      selectedOrgId,
      _orgData,
      orgData.data.results
    );
  } else if (
    orgData &&
    orgData.data &&
    orgData.data.results &&
    orgData.data.results.length
  ) {
    console.log("HELLO", orgData.data.results);

    // Regular Users who get a single org
    _orgData = orgData.data.results[0];
    window.localStorage.setItem("selectedOrg", _orgData.id);
  }
  _orgData && window.localStorage.setItem("org", JSON.stringify(_orgData));
  return _orgData;
};

export const initialRoutes = [
  {
    path: "*",
    element: <MainLayout />,
    children: [
      // Main Application Routes
      {
        path: "app",
        element: (
          <AuthGuard>
            <DashboardLayout />
          </AuthGuard>
        ),
        children: [
          {
            path: "/",
            element: <Home />,
          },
        ],
      },
      {
        path: "/forgot-password",
        element: (
          <GuestGuard>
            <PasswordRecovery />
          </GuestGuard>
        ),
      },
      {
        path: "/login",
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        ),
      },
      {
        path: "401",
        element: <AuthorizationRequired />,
      },
      {
        path: "404",
        element: <NotFound />,
      },
      {
        path: "500",
        element: <ServerError />,
      },
    ],
  },
];

//helpers for calibrationSideBarStepOne

export const getColorString = (string) => {
  let color = "primary";
  switch (string) {
    case "done":
      color = "success";
      break;
    case "in_progress":
      color = "primary";
      break;
    case "to_do":
      color = "default";
      break;
    case "failed":
      color = "error";
      break;
    default:
      break;
  }
  return color;
};

export const formatLabel = (label) => {
  let updated = label;
  if (label === "done") updated = "Done";
  if (label === "in_progress") updated = "In-progress";
  if (label === "to_do") updated = "To do";
  if (label === "failed") updated = "Failed";
  return updated;
};

export const getStatus = (statusData) => {
  const status = {
    "Accessing Data Source": statusData?.accessing_data_source || "to_do",
    "Queries Generating": statusData?.queries_generated || "to_do",
    "Data Processing": statusData?.data_extraction || "to_do",
    "Analytics Processing": statusData?.monolith || "to_do",
    "ML Process Initiated": statusData?.ml_process_initiated || "to_do",
    "Reports Generated": statusData?.reporting || "to_do",
    "Onboarding Complete": statusData?.landing || "to_do",
  };
  return status;
};

export const getTopicFormatted = (label) => {
  switch (label) {
    case "engagement":
      return "User Engagement & Customer Activity";
    case "revenue":
      return "Revenue Generation";
    case "efficiency":
      return "Operational Efficiency & Unit Economics";
    case "user_engagement":
      return "User Engagement";
    case "user_acquisition":
      return "User Acquisition";
    case "strategic_drift":
      return "Strategic Drift";
  }
};

export const getTabAction = (v) => {
  switch (v) {
    case "investigate":
      return (
        <Fade in={true}>
          <Typography
            style={{ color: "rgba(125, 135, 170, 1)", fontSize: "16px" }}
          >
            <b>Investigate</b> the issue in more detail.
          </Typography>
        </Fade>
      );
    case "experiment":
      return (
        <Fade in={true}>
          <Typography
            style={{ color: "rgba(125, 135, 170, 1)", fontSize: "16px" }}
          >
            <b>Experiment</b> to quantify the incremental financial impact of
            each strategy.
          </Typography>
        </Fade>
      );
    case "implement":
      return (
        <Fade in={true}>
          <Typography
            style={{ color: "rgba(125, 135, 170, 1)", fontSize: "16px" }}
          >
            <b>Implement</b> contermeasures to resolve the issue.
          </Typography>
        </Fade>
      );
  }
};
