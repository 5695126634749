import * as Yup from 'yup';
import { Formik } from 'formik';
import { Box, Button, FormHelperText, TextField } from '@material-ui/core';
import useAuth from '../../../hooks/useAuth';
import useMounted from '../../../hooks/useMounted';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  root: {                           // - The TextField-root

  }
}));

const LoginJWT = (props) => {

  const mounted = useMounted();
  const classes = useStyles();
  const { login } = useAuth();

  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
        submit: null
      }}
      validationSchema={Yup
        .object()
        .shape({
          email: Yup
            .string()
            .email('Must be a valid email')
            .max(255)
            .required('Email is required'),
          password: Yup
            .string()
            .max(255)
            .required('Password is required')
        })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          const loginResponse = await login(values.email, values.password);

          if (loginResponse && loginResponse.err) {
            setStatus({ success: false });
            setErrors({ submit: 'Invalid email or password' });
          } else {
            console.log(loginResponse);
            if (mounted.current) {
              setStatus({ success: true });
              setSubmitting(false);
            }
          }
        } catch (err) {
          console.error(err);
          if (mounted.current) {
            setStatus({ success: false });
            setErrors({ submit: err.message });
            setSubmitting(false);
          }
        }
      }}
    >
      {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
        <form
          noValidate
          onSubmit={handleSubmit}
          {...props}
        >
          <TextField
            error={Boolean(touched.email && errors.email)}
            color="info"
            fullWidth
            helperText={touched.email && errors.email}
            label="Email Address"
            margin="normal"
            name="email"
            onBlur={handleBlur}
            onChange={handleChange}
            type="email"
            value={values.email}
            variant="outlined"
            className={classes.root}
            inputProps={{
              style: { WebkitBoxShadow: "0 0 0 1000px #1D2033 inset", borderRadius: '16px' }
            }}
          />
          <TextField
            error={Boolean(touched.password && errors.password)}
            color="info"
            fullWidth
            helperText={touched.password && errors.password}
            label="Password"
            margin="normal"
            name="password"
            onBlur={handleBlur}
            onChange={handleChange}
            type="password"
            value={values.password}
            variant="outlined"
            className={classes.root}
            inputProps={{
              style: { WebkitBoxShadow: "0 0 0 1000px #1D2033 inset", borderRadius: '16px' }
            }}
          />
          {errors.submit && (
            <Box sx={{ mt: 3 }}>
              <FormHelperText error>
                {errors.submit}
              </FormHelperText>
            </Box>
          )}
          <Box sx={{ mt: 2, py: 2 }}>
            <Button
              color="primary"
              disabled={isSubmitting}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
            >
              Log In
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default LoginJWT;
