import Icon from '@ant-design/icons';

const VizDevelopmentSvg = () => (
    <svg viewBox="0 0 20 20" focusable="false" width="1em" height="1em" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M20.8125 18.5625H4.6875V3.9375C4.6875 3.83437 4.60312 3.75 4.5 3.75H3.1875C3.08437 3.75 3 3.83437 3 3.9375V20.0625C3 20.1656 3.08437 20.25 3.1875 20.25H20.8125C20.9156 20.25 21 20.1656 21 20.0625V18.75C21 18.6469 20.9156 18.5625 20.8125 18.5625ZM6.75 14.1562C6.75 14.5541 6.90804 14.9356 7.18934 15.2169C7.47064 15.4982 7.85218 15.6562 8.25 15.6562C8.64782 15.6562 9.02936 15.4982 9.31066 15.2169C9.59196 14.9356 9.75 14.5541 9.75 14.1562C9.75 13.7584 9.59196 13.3769 9.31066 13.0956C9.02936 12.8143 8.64782 12.6562 8.25 12.6562C7.85218 12.6562 7.47064 12.8143 7.18934 13.0956C6.90804 13.3769 6.75 13.7584 6.75 14.1562ZM9.51562 8.90625C9.51562 9.20462 9.63415 9.49077 9.84513 9.70175C10.0561 9.91272 10.3423 10.0312 10.6406 10.0312C10.939 10.0312 11.2251 9.91272 11.4361 9.70175C11.6471 9.49077 11.7656 9.20462 11.7656 8.90625C11.7656 8.60788 11.6471 8.32173 11.4361 8.11075C11.2251 7.89978 10.939 7.78125 10.6406 7.78125C10.3423 7.78125 10.0561 7.89978 9.84513 8.11075C9.63415 8.32173 9.51562 8.60788 9.51562 8.90625ZM13.2188 14.25C13.2188 14.8467 13.4558 15.419 13.8778 15.841C14.2997 16.2629 14.872 16.5 15.4688 16.5C16.0655 16.5 16.6378 16.2629 17.0597 15.841C17.4817 15.419 17.7188 14.8467 17.7188 14.25C17.7188 13.6533 17.4817 13.081 17.0597 12.659C16.6378 12.2371 16.0655 12 15.4688 12C14.872 12 14.2997 12.2371 13.8778 12.659C13.4558 13.081 13.2188 13.6533 13.2188 14.25ZM16.6875 6.89062C16.6875 7.23872 16.8258 7.57256 17.0719 7.8187C17.3181 8.06484 17.6519 8.20312 18 8.20312C18.3481 8.20312 18.6819 8.06484 18.9281 7.8187C19.1742 7.57256 19.3125 7.23872 19.3125 6.89062C19.3125 6.54253 19.1742 6.20869 18.9281 5.96255C18.6819 5.71641 18.3481 5.57812 18 5.57812C17.6519 5.57812 17.3181 5.71641 17.0719 5.96255C16.8258 6.20869 16.6875 6.54253 16.6875 6.89062Z" fill="currentColor"/>
        <path d="M20.8125 18.5625H4.6875V3.9375C4.6875 3.83437 4.60312 3.75 4.5 3.75H3.1875C3.08437 3.75 3 3.83437 3 3.9375V20.0625C3 20.1656 3.08437 20.25 3.1875 20.25H20.8125C20.9156 20.25 21 20.1656 21 20.0625V18.75C21 18.6469 20.9156 18.5625 20.8125 18.5625Z" fill="currentColor" />
        <path d="M13.2188 7.1875C13.2188 7.08437 13.1344 7 13.0312 7H11.9062C11.8031 7 11.7188 7.08437 11.7188 7.1875V9.71875H9.1875C9.08437 9.71875 9 9.80312 9 9.90625V11.0312C9 11.1344 9.08437 11.2188 9.1875 11.2188H11.7188V13.75C11.7188 13.8531 11.8031 13.9375 11.9062 13.9375H13.0312C13.1344 13.9375 13.2188 13.8531 13.2188 13.75V11.2188H15.75C15.8531 11.2188 15.9375 11.1344 15.9375 11.0312V9.90625C15.9375 9.80312 15.8531 9.71875 15.75 9.71875H13.2188V7.1875Z" fill="currentColor" />
    </svg>
);

const VizDevelopmentComponent = props => <Icon component={VizDevelopmentSvg} {...props} />;

export default VizDevelopmentComponent;